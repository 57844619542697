
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { WniTableRowUtil } from 'wni-portals-util-react';

import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { MetadataContent } from '@jutro/uiconfig';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';

import metadata from './CPRiskSummaryComponent.metadata.json5';
import styles from './CPRiskSummaryComponent.module.scss';
import CPRisksUtil from '../../CPRisksUtil';

// from CPRisksPage
function CPRiskSummaryComponent(props) {
    const {
        model: dtoVM = {},
        onValueChange = _.noop,
        showErrors = false,
        //
        riskTreeRows: riskTreeRowsParam,
    } = props;

    const { authHeader, authUserData } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);

    // const [selectedRiskItem, setSelectedRiskItem]  = useState();
    const [submissionVM, updateSubmissionVM] = useState(riskTreeRowsParam);

    const riskTreeRows = submissionVM;
    const risksTableData = CPRisksUtil.getRisksTableData(submissionVM);

    // useEffect(() => {
    //     const activeCoverableType = selectedRiskItem ? _.get(selectedRiskItem, 'coverableType') : null;
    //     const activePublicID = selectedRiskItem ? _.get(selectedRiskItem, 'publicID') : null;
    //     WniTableRowUtil.setTablePublicIDSelected(`${activeCoverableType}${activePublicID}`, "risksTable");
    // }, [selectedRiskItem]);

    const riskTreeRowsPrefix = ''; // 'value.lobData.commercialProperty.riskTreeRows';


    // from CPRisksPage::getCoverableDetailsAndSubnodes()
    const getCoverableDetailsAndSubnodes = async(e, item, isCollapsed) => {
        e.stopPropagation();
        // setLoadingMask(true);
        let locationIndex = riskTreeRows.findIndex(loc => loc.publicID === item.locationPublicID);
        if (item.coverableType === "CP7 Location") {
            locationIndex = riskTreeRows.findIndex(loc => loc.publicID === item.publicID);
            const locationPath = `${riskTreeRowsPrefix}[${locationIndex}]`;
            const location = _.get(submissionVM, locationPath, []);
            _.set(location, 'collapsed', !isCollapsed);
            if (isCollapsed) {
                _.set(submissionVM, locationPath, {...location, openBuildingTreeRows: true, openSpecialCLassTreeRows: true});
            } else {
                _.set(submissionVM, locationPath, {...location, openBuildingTreeRows: false, openSpecialCLassTreeRows: false});
            }
        }
        if (item.coverableType === "Building") {
            const buildingIndex = riskTreeRows[locationIndex].buildingTreeRows?.findIndex(elt => elt.publicID === item.publicID);
            const buildingPath = `${riskTreeRowsPrefix}[${locationIndex}].buildingTreeRows[${buildingIndex}]`;
            const building = _.get(submissionVM, buildingPath, []);
            _.set(building, 'coverableColumnBasicInfo.collapsed', !isCollapsed);
            if (isCollapsed) {
                _.set(submissionVM, buildingPath, {...building, openOccupancyTreeRows: true, openBusinessIncomeRows: true});
            } else {
                _.set(submissionVM, buildingPath, {...building, openOccupancyTreeRows: false, openBusinessIncomeRows: false});
            }
        }
        if (item.coverableType === "Special Class") {
            const specialCLassIndex = riskTreeRows[locationIndex].specialCLassTreeRows?.findIndex(elt => elt.publicID === item.publicID);
            const specialCLassPath = `${riskTreeRowsPrefix}[${locationIndex}].specialCLassTreeRows[${specialCLassIndex}]`;
            const specialCLass = _.get(submissionVM, specialCLassPath, []);
            _.set(specialCLass, 'coverableColumnBasicInfo.collapsed', !isCollapsed);
            if (isCollapsed) {
                _.set(submissionVM, specialCLassPath, {...specialCLass, openSpecialClassBusinessIncomeTreeRows: true});
            } else {
                _.set(submissionVM, specialCLassPath, {...specialCLass, openSpecialClassBusinessIncomeTreeRows: false})
            }
            
        }
        if (item.coverableType === "Occupancy") {
            const buildingIndex = riskTreeRows[locationIndex].buildingTreeRows?.findIndex(elt => elt.publicID === item.buildingPublicID);
            const buildingPath = `${riskTreeRowsPrefix}[${locationIndex}].buildingTreeRows[${buildingIndex}]`;
            const building = _.get(submissionVM, buildingPath, []);
            const occupancyIndex = building.occupancyTreeRows?.findIndex(elt => elt.publicID === item.publicID);
            const occupancyPath = `${buildingPath}.occupancyTreeRows[${occupancyIndex}]`;
            const occupancy = _.get(submissionVM, occupancyPath, []);
            _.set(occupancy, 'coverableColumnBasicInfo.collapsed', !isCollapsed);
            if (isCollapsed) {
                _.set(submissionVM, occupancyPath, {...occupancy, openPersonalPropertyTreeRows: true});
            } else {
                _.set(submissionVM, occupancyPath, {...occupancy, openPersonalPropertyTreeRows: false})
            }
            
        }
        updateSubmissionVM(submissionVM);
        // setLoadingMask(false);
    }

    const renderDescriptionCell = CPRisksUtil.getRenderDescriptionCellFuncForCPRiskSummaryComponent(getCoverableDetailsAndSubnodes);

    const writeValue = (value, path) => {
        onValueChange(value, path);
    };

    //----------------------------------
    const overrideProps = {
        '@all': {
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        risksTable: {
            data: risksTableData,
            onRowClick: _.noop,
            // onRowClick: (item) => {
            //     if (_.isNil(selectedRiskItem) || (selectedRiskItem.coverableType === "CP7 Location"))
            //         {setSelectedRiskItem(item)}
            //     }
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            renderDescriptionCell,
        },
        resolveComponentMap: {

        },
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            dtoVM,
            id,
            path,
            overrideProps
        );
    };
    //---------
    // return (
    //     <MetadataContent
    //             uiProps={metadata.componentContent}
    //             overrideProps={overrides}
    //             {...resolvers} />
    // );
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={dtoVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            // onModelChange={updateModelValue}
            resolveValue={readValue}
            onValueChange={writeValue}
            showErrors={showErrors}
        />
    );
}

CPRiskSummaryComponent.propTypes = {
    
};

CPRiskSummaryComponent.defaultProps = {
    // lobData: {},
};

export default CPRiskSummaryComponent;
