import { defineMessages } from 'react-intl';

export default defineMessages({
    scheduleAdd: {
        id: 'InlandMarine.accountsReceivable.schedules.table.Add',
        defaultMessage: 'Add',
    },
    scheduleDel: {
        id: 'InlandMarine.accountsReceivable.schedules.table.del',
        defaultMessage: 'Delete Selected'
    },
    scheduleEdit: {
        id: 'InlandMarine.accountsReceivable.schedules.table.edit',
        defaultMessage: 'Edit'
    },
    scheduleView: {
        id: 'InlandMarine.accountsReceivable.schedules.table.view',
        defaultMessage: 'View'
    },
    
})