import {
    Button,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalNext,
} from '@jutro/components';
import { Flex } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
    WniCheckboxField,
    WniDropdownSelect,
    WniInputText,
} from 'wni-common-base-components';
import messages from './SearchFromAddressBookPopup.messages';
import styles from './SearchFromAddressBookPopup.module.scss';

function SearchFromAddressBookPopup(props) {
    const translator = useTranslator();

    const { onValidate, isComponentValid } = useValidation(
        'SearchFromAddressBookPopup'
    );

    const {
        size,
        isOpen,
        onResolve,
        onReject,
        searchAddressBookFunc,
        addNewSelectContactInfoMortgageeWhileUpdateMortgageeFunc,
    } = props;

    const [searchCriteria, setSearchCriteria] = useState({
        bankName: undefined,
        keywordExact: false,
        taxID: undefined,
        phone: undefined,
    });

    const [showErrors, setShowErrors] = useState(false);

    const [searchCriteriaSnapshot, setSearchCriteriaSnapShot] = useState();

    const [searchResults, setSearchResults] = useState();

    const handleSearch = async () => {
        if (!isComponentValid) {
            setShowErrors(true);
            return;
        }
        const contactResults = await searchAddressBookFunc(searchCriteria);
        setSearchResults(contactResults);
        setSearchCriteriaSnapShot(searchCriteria);
    };

    const handleSelect = async (contactResultId) => {
        await addNewSelectContactInfoMortgageeWhileUpdateMortgageeFunc(
            searchCriteriaSnapshot,
            contactResultId
        );
        onResolve();
    };

    const hasFEINOrWorkPhone = searchCriteria.taxID || searchCriteria.phone;

    const validBankName =
        searchCriteria.bankName && searchCriteria.bankName.length >= 5;
    const showBankNameValidationMessage = !hasFEINOrWorkPhone && !validBankName;
    const taxIDRegexPattern = /^(\d{3}-\d{3}-\d{3}|\d{2}-\d{7})$/;
    const showTaxIDValidationMessage =
        searchCriteria.taxID && !taxIDRegexPattern.test(searchCriteria.taxID);

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={translator(messages.SearchAddressBook)} />
            <ModalBody id="AddressBookSearchComponent" autoFocus={false}>
                <WniDropdownSelect
                    id="Type"
                    label={translator(messages.Type)}
                    value="Bank"
                    availableValues={[
                        { code: 'Bank', name: translator(messages.Bank) },
                    ]}
                    readOnly
                />
                <WniInputText
                    id="Bank Name"
                    label={translator(messages.Bank)}
                    labelPosition="left"
                    value={searchCriteria.bankName}
                    onValueChange={(value) =>
                        setSearchCriteria({
                            ...searchCriteria,
                            bankName: value,
                        })
                    }
                    onValidationChange={onValidate}
                    showErrors={showErrors}
                    validationMessages={
                        showBankNameValidationMessage
                            ? [messages.bankNameValidationMessage]
                            : undefined
                    }
                />
                <WniCheckboxField
                    id="Bank name is an exact match"
                    label={translator(messages.BankNameIsAnExactMatch)}
                    value={searchCriteria.keywordExact}
                    onValueChange={(value) =>
                        setSearchCriteria({
                            ...searchCriteria,
                            keywordExact: value,
                        })
                    }
                />
                <WniInputText
                    id="TaxID"
                    label={translator(messages.TaxID)}
                    labelPosition="left"
                    value={searchCriteria.taxID}
                    onValidationChange={onValidate}
                    onValueChange={(value) =>
                        setSearchCriteria({
                            ...searchCriteria,
                            taxID: value,
                        })
                    }
                    validationMessages={
                        showTaxIDValidationMessage
                            ? [messages.taxIDFormatNotCorrectMessage]
                            : undefined
                    }
                />
                <WniInputText
                    id="Work Phone"
                    label={translator(messages.WorkPhone)}
                    labelPosition="left"
                    value={searchCriteria.phone}
                    onValidationChange={onValidate}
                    onValueChange={(value) =>
                        setSearchCriteria({
                            ...searchCriteria,
                            phone: value,
                        })
                    }
                />
                <Flex justifyContent="right">
                    <Button onClick={handleSearch} className={styles.searchBtn}>
                        {translator(messages.Search)}
                    </Button>
                </Flex>
                {searchResults && (
                    <>
                        <hr />
                        <ul id="results" className={styles.noDecorator} />
                        {_.isEmpty(searchResults) ? (
                            <Flex justifyContent="center">
                                <h4>No Results</h4>
                            </Flex>
                        ) : (
                            searchResults.map((item) => {
                                return (
                                    <li
                                        className={styles.resultLi}
                                        key={item.id}
                                    >
                                        <Button
                                            className={styles.selectBtn}
                                            onClick={() =>
                                                handleSelect(item.id)
                                            }
                                        >
                                            Select
                                        </Button>
                                        <span className={styles.resultItem}>
                                            {item.displayName}
                                        </span>
                                    </li>
                                );
                            })
                        )}
                    </>
                )}
            </ModalBody>
            <ModalFooter
                contentLayout={{
                    component: 'flex',
                    componentProps: {
                        alignItems: 'middle',
                        justifyContent: 'between',
                        wrap: 'reverse',
                        gap: 'medium',
                    },
                }}
            >
                <Button
                    onClick={() => {
                        onReject();
                    }}
                    type="outlined"
                >
                    Close
                </Button>
                {searchResults && (
                    <>
                        <Button
                            onClick={() => {
                                onReject('person');
                            }}
                        >
                            Create New Person
                        </Button>
                        <Button
                            onClick={() => {
                                onReject('company');
                            }}
                        >
                            Create New Company
                        </Button>
                    </>
                )}
            </ModalFooter>
        </ModalNext>
    );
}
SearchFromAddressBookPopup.propTypes = {
    size: PropTypes.string,
    isOpen: PropTypes.bool,
    onReject: PropTypes.func,
    onResolve: PropTypes.func,
};

SearchFromAddressBookPopup.defaultTypes = {
    size: 'lg',
    isOpen: false,
    onResolve: _.noop,
    onReject: _.noop,
};

export default SearchFromAddressBookPopup;
