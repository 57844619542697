import React, {useEffect, useCallback} from 'react'
import _ from 'lodash'
import { AccordionCard } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { IMAccountsReceivableService } from 'wni-capability-quoteandbind-im';
import messages from './CoveragesAccordionCard.messages'

import IMCoverageUtil from '../../../../util/IMCoverageUtil';
import CoverageContext from '../../../../context/IMCoverageContext';
import IMSingleClauseComponentVM from '../../../../components/IMSingleClauseComponentVM';
import IMClausesComponentVM from '../../../../components/IMClausesComponentVM';
import SingleClauseContext from '../../../../context/IMSingleClauseContext';
import IMTermComponent from '../../../../components/IMSingleClauseComponentVM/IMTermComponent';
import CoveragePartSchedule from './CoveragePartSchedule';

const CoveragesAccordionCard = (props) => {

    const {
        coveragePartClauses,
        setCoveragePartClauses,
        isEditing,
        setIsEditing,
        isEditable,
        showErrors,
        onValidate: onPageValidate,
        loadingClauses,
        setLoadingClauses,

        wizardData: submissionVM,
        updateWizardDataWhileSetPeriodStatus,
    } = props

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const { mainCoverages, accountsReceivablePartCoverages, coveragePartSchedule } = coveragePartClauses;

    const [imaccountReceivableCov] = mainCoverages

    const {
        code_Ext: imaccountReceivableCovCode,
        terms: imaccountReceivableCovTerms
    } = imaccountReceivableCov


    const {
        jobID,
        sessionUUID,
    } = submissionVM.value

    const translator = useTranslator();

    const validationID = 'AccountsReceivableCoveragesAccordionCard'

    const { onValidate, isComponentValid } = useValidation(validationID);
    const { authHeader } = useAuthentication();

    useEffect(() => {
        if (onPageValidate) {
            onPageValidate(isComponentValid, validationID)
        }
        return () => {
            if (onPageValidate) {
                onPageValidate(true, validationID)
            }
            
        }
        
    }, [isComponentValid, onPageValidate])

    const changeClauses = useCallback(
        (value, changedPath, clausesPath, originClauses) => {
            const newClauses = IMCoverageUtil.setClauseValue(originClauses, clausesPath, value, changedPath);
            const newCoveragePartClauses = _.clone(coveragePartClauses)
            _.set(newCoveragePartClauses, clausesPath, newClauses);
            setCoveragePartClauses(newCoveragePartClauses);
            setIsEditing(false);
            return newCoveragePartClauses;
        },
        [coveragePartClauses, setCoveragePartClauses, setIsEditing]
    );

    const changeAccountsReceivablePartCoverages = useCallback(
        (value, changedPath) => {
            return changeClauses(value, changedPath, 'accountsReceivablePartCoverages', accountsReceivablePartCoverages);
        },
        [accountsReceivablePartCoverages, changeClauses]
    );

    const changeMainCoverages = useCallback(
        (value, changedPath) => {
            return changeClauses(value, changedPath, 'mainCoverages', mainCoverages);
        },
        [changeClauses, mainCoverages]
    );


    const updateVMToServer = useCallback(async (newCoveragePartClauses, setLoadingClausesFunc, setEditingFunc) => {
        const coveragePartClausesToUpdate = IMCoverageUtil.generateUpdatedAccountReceivableClausesDTO(newCoveragePartClauses);
        const updatedCoveragePartClausesPublicIDs = IMCoverageUtil.getUpdatedCoveragesCodesArrayByUpdatedLineClausesDTO(coveragePartClausesToUpdate);
        setLoadingMask(true)
        setLoadingClausesFunc(updatedCoveragePartClausesPublicIDs)
        const coveragePartClausesFromServer = await IMAccountsReceivableService.updateAccountsReveivableClauses(
            jobID,
            sessionUUID,
            coveragePartClausesToUpdate,
            authHeader
        )
        setLoadingClausesFunc(false)
        setEditingFunc(false)
        setLoadingMask(false)
        setCoveragePartClauses(coveragePartClausesFromServer);
        updateWizardDataWhileSetPeriodStatus(submissionVM)
    }, [setLoadingMask, jobID, sessionUUID, authHeader, setCoveragePartClauses, updateWizardDataWhileSetPeriodStatus, submissionVM]);

    const updateNewCoveragePartClausesToServer = useCallback(async (newCoveragePartClauses) => {
        updateVMToServer(newCoveragePartClauses, setLoadingClauses, setIsEditing)
    }, [updateVMToServer, setLoadingClauses, setIsEditing]);

    const changeMainCoveragesAndSync = useCallback(
        (value, changedPath) => {
            const newCoveragePartClauses = changeMainCoverages(value, changedPath);
            
            updateNewCoveragePartClausesToServer(newCoveragePartClauses);
        },
        [changeMainCoverages, updateNewCoveragePartClausesToServer]
    );

    const changeAccountsReceivablePartClauseAndSync = useCallback(
        (value, changedPath) => {
            const newCoveragePartClauses = changeAccountsReceivablePartCoverages(value, changedPath);
            
            updateNewCoveragePartClausesToServer(newCoveragePartClauses);
        },
        [changeAccountsReceivablePartCoverages, updateNewCoveragePartClausesToServer]
    );

    const onSyncCoverages = useCallback(
        () => {
            updateNewCoveragePartClausesToServer(coveragePartClauses);
        },
        [coveragePartClauses, updateNewCoveragePartClausesToServer]
    )

    return (
        <AccordionCard
            id="AccountsReceivableCoverages"
            chevron
            className="coveragesAccordion"
            cardBodyClassName="accordionCardBody"
            cardHeadingClassName="coveragesAccordionHeader font-capitalize"
            title={translator(messages.Coverages)}
            errorState={showErrors && !isComponentValid}
        >
            <div className="formCoveragesContainer">
                <CoverageContext.Provider
                    value={{
                        wizardData: submissionVM,
                        updateWizardData: updateWizardDataWhileSetPeriodStatus,
                        clausesContainer: coveragePartClauses,
                        updateClausesContainer: setCoveragePartClauses,
                        updateVMToServer: updateVMToServer
                    }}
                >
                    <SingleClauseContext.Provider value={{
                        clauseCode: imaccountReceivableCovCode,
                        clausePath: 'mainCoverages[0]',
                        isEditable,
                        // isDisabled,
                        onChangeClause: changeMainCoverages,
                        onSyncCoverages: onSyncCoverages,
                        onChangeSubmissionAndSync: changeMainCoveragesAndSync,
                        labelPosition: 'left',
                        onValidate,
                        setIsEditing,
                        showErrors,
                    }}>
                        {imaccountReceivableCovTerms.map(term => {
                            const termIndex = imaccountReceivableCovTerms
                                .findIndex((clauseTerm) => _.get(clauseTerm, 'code_Ext') === _.get(term, 'code_Ext'))
                            return <IMTermComponent
                                key={_.get(term, 'code_Ext')}
                                term={term}
                                termIndex = {termIndex}
                            />
                        })}
                    </SingleClauseContext.Provider>
                    <IMClausesComponentVM
                        splitByClauseCategory={false}
                        sortBySelected
                        onSyncCoverages={onSyncCoverages}
                        onChangeSubmissionAndSync={changeAccountsReceivablePartClauseAndSync}
                        onChangeClause={changeAccountsReceivablePartCoverages}
                        onValidate={onValidate}
                        showAmount={false}
                        loadingClause={loadingClauses}
                        path="accountsReceivablePartCoverages"
                        value={accountsReceivablePartCoverages}
                        showErrors={showErrors}
                        componentMapOverrides={{
                            IMSingleClauseComponentVM: IMSingleClauseComponentVM,
                        }}
                        setIsEditing={setIsEditing}
                        isEditing={isEditing}
                        isEditable={isEditable}
                        hideCheckBoxForRequired
                    />
                    {/* <SingleClauseContext.Provider value={{
                        clauseCode: imaccountReceivableScheduleCovCode,
                        clausePath: 'mainCoverages[1]',
                        isEditable,
                        // isDisabled,
                        onChangeClause: changeMainCoverages,
                        onSyncCoverages: onSyncCoverages,
                        onChangeSubmissionAndSync: changeMainCoveragesAndSync,
                        labelPosition: 'left',
                        onValidate,
                        setIsEditing,
                        showErrors,
                    }}>
                        <IMScheduleTable schedule={imAccountReceivableSchedule} />
                    </SingleClauseContext.Provider> */}
                    <CoveragePartSchedule
                        jobID={jobID}
                        sessionUUID={sessionUUID}
                        schedule={coveragePartSchedule}
                        isEditable={isEditable}
                        setCoveragePartClauses={setCoveragePartClauses}
                    />
                </CoverageContext.Provider>
                
            </div>
        </AccordionCard>
    )
}

export default CoveragesAccordionCard