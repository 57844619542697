import React from 'react'
import _ from 'lodash';
import { WniDropdownSelect } from 'wni-common-base-components'

import { ValuePath } from '../../../../../util/IMScheduleItemsUtil'


function formatAvailableValues(availableValues) {
    return _.sortBy(availableValues, 'priority')
        .map((availableValue) => ({
            code: availableValue.code,
            name: availableValue.name
        }));
}

const PropertyFieldDropdown = (props) => {
    const {
        id,
        propertyInfo,
        propertyInfoItemData,
        setScheduleItemFunc,
        showErrors,
        onValidate,
    } = props

    const {
        id: propertyInfoId,
        required,
    } = propertyInfo

    const {
        options_Ext: allOptions = [],
        [ValuePath.TypeCodeValue]: value,
        available_Ext: available
    } = propertyInfoItemData

    const optionValues = allOptions

    const availableValues = formatAvailableValues(optionValues)

    const onDropdownValueChange = (newValue) => {
        const newItemDataField = {
            ...propertyInfoItemData,
            [ValuePath.TypeCodeValue]: newValue,
            updated_Ext: true
        };
        setScheduleItemFunc((oldScheduleItem) => {
            const newScheduleItem = _.clone(oldScheduleItem)
            _.set(newScheduleItem, `itemData.${propertyInfoId}`, newItemDataField)
            return newScheduleItem
        })
    }

    return <WniDropdownSelect
        id = {id}
        dataType = 'string'
        required = {required}
        showRequired = {required}
        availableValues = {availableValues}
        value = {value}
        onValueChange = {onDropdownValueChange}
        showErrors = {showErrors}
        disabled={!available}
        onValidate={onValidate}
    />
}

export default PropertyFieldDropdown