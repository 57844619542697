import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { IMCoveragePartsService } from 'wni-capability-quoteandbind-im';
import {
    // ErrorsAndWarningsUtil,
    // WizardUtil,
    // WindowUtil,
    QuoteUtil,
} from 'wni-portals-util-js';
// import { ErrorsAndWarningsDisplayComponent, ValidationIssuesComponent } from 'wni-components-platform-react';
// import { PortalConstants, WizardConstants } from 'wni-portals-config-js';
// eslint-disable-next-line import/no-unresolved
import WizardPage from '../../templates/IMWizardPage';

import metadata from './IMCoveragePartSelectionPage.metadata.json5';

function IMCoveragePartSelectionPage(props) {
    const {
        wizardData: submissionVM,
        // updateWizardData: updateSubmissionVM,
        updateWizardSnapshot,
        updateWizardReadOnly,
        wizardSnapshot,
        //
        wizardStepToFieldMapping,
        markFollowingWizardStepsUnvisited,
        jumpTo: wizardJumpTo,
        steps: wizardSteps,
        //
        wizardPageData,
        updateWizardPageData,
        imCoveragePartsService
    } = props;

    // const {
    //     jobID, sessionUUID,
    //     baseData: {
    //         termType,
    //         periodStatus,
    //         selectedVersion_Ext: selectedVersion,
    //         accountHolder: {
    //             primaryAddress: {
    //                 postalCode,
    //             }
    //         },
    //         displayStatus_Ext: displayStatus,
    //     },
    //     lobData: {
    //         'inlandMarine': {
    //             //
    //         }
    //     },
    // } = submissionVM.value;

    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    // const ViewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const viewModelService = useContext(ViewModelServiceContext);
    const {
        initialValidation,
        onValidate,
        invalidFields,
        isComponentValid,
    } = useValidation('IMCoveragePartSelectionPage');

    const [validationIssues, updateValidationIssues] = useState(undefined);
    const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);

    useEffect(() => {
        setLoadingMask(isServiceCallInProgress);
    }, [isServiceCallInProgress, setLoadingMask]);

    const handleValidation = useCallback(() => {
        //
    }, [invalidFields]);

    const onPageNext = useCallback(async () => {
        //
        return submissionVM;
    }, [authHeader, submissionVM]);

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
            },
            itemData: {
                data: _.get(submissionVM, 'value.lobData.inlandMarine.selectableCoverageParts') || []
            },
            coveragePartTable: {
                data: _.get(submissionVM, 'value.lobData.inlandMarine.selectedCoverageParts') || []
            }
        };
    }, [breakpoint, submissionVM]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={isComponentValid ? onPageNext : handleValidation}
            pageLevelValidationIssues={validationIssues}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                // onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                // onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

IMCoveragePartSelectionPage.propTypes = {
    ...wizardProps,
    imCoveragePartsService: PropTypes.object
};

IMCoveragePartSelectionPage.defaultProps = {
    imCoveragePartsService: IMCoveragePartsService
};
export default IMCoveragePartSelectionPage;