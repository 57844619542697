import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('imCoveragePartsService'), method, data, additionalHeaders);
}

export default class IMCoveragePartsService {

    static addCoverageParts(data, authHeader) {
        return processSubmission('addCoverageParts', [data.jobID, data.sessionUUID, data.dtos], authHeader);
    }

    static removeCoverageParts(data, authHeader) {
        return processSubmission('removeCoverageParts', [data.jobID, data.sessionUUID, data.dtos], authHeader);
    }

}