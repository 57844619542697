import React, {
    useCallback,
    useState,
    useMemo
} from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
// import { Loader } from '@jutro/components';
import { InputField, Accordion, AccordionCard} from '@jutro/components';
import { DataTable, DisplayColumn } from '@jutro/datatable';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import {
    QuoteUtil,
} from 'wni-portals-util-js';
import { PortalConstants } from 'wni-portals-config-js';
import {
    GLModifierService
} from 'wni-capability-quoteandbind-gl';
import WizardPage from '../../templates/GLWizardPage';
import GLModifiersConfig from './GLModifiersPage.config';
import messages from './GLModifiersPage.messages';

function GLModifiersPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
    } = props;

    const translator = useTranslator();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const { authHeader, authUserData } = useAuthentication();
    const isExternalUser = _.get(authUserData, 'isExternalUser_Ext');
    const {
        lobData: {
            generalLiability: {
                modifiers_Ext : modifiers
            }
        },
        jobID,
        sessionUUID,
    } = submissionVM.value;
    const {
        agentStateTotalRangeMap
    } = GLModifiersConfig

    const states = modifiers.map(modifier => modifier.jurisdiction);
    // currently, only focus on AK, this story will be revisited, then follow up other states
    const modifierAK = modifiers.find(modifier => modifier.jurisdiction === 'Alaska');
    const overallMinAK = useMemo(() => {
        if (!_.isNil(modifierAK)) {
            return isExternalUser ? _.get(agentStateTotalRangeMap, `${modifierAK.jurisdiction}.minimum`) : modifierAK.overallMinimum;
        }
        return undefined;
    }, [agentStateTotalRangeMap, isExternalUser, modifierAK])
    const overallMaxAK = useMemo(() => {
        if (!_.isNil(modifierAK)) {
            return isExternalUser ? _.get(agentStateTotalRangeMap, `${modifierAK.jurisdiction}.maximum`) : modifierAK.overallMaximum;
        }
        return undefined;
    }, [agentStateTotalRangeMap, isExternalUser, modifierAK])
    const defaultValidationInfo = useMemo(() => {
        return _.isNil(modifierAK) ? [] : [{
            type: 'info',
            reason: translator(messages.modifierRangeInfo, {max: `${Math.round(overallMaxAK * 100)}%`})
    }];}, [modifierAK, overallMaxAK, overallMinAK, translator])

    const getRateFactorID = (rateFactor) => _.get(rateFactor, 'patternCode');
    const allStateTotalCreditDebit = modifiers.map(modifier => {
        return {state: modifier.jurisdiction, overallCreditDebit: modifier.rateFactors.reduce((acc, currentFactor) => acc + currentFactor.creditDebit, 0)}
    });
    const [stateTotalCreditDebit, updateStateTotalCreditDebit] = useState(allStateTotalCreditDebit);
    const stateOverallMinMax = modifiers.map(modifier => {
        return {state: modifier.jurisdiction, overallMin: modifier.overallMinimum, overallMax: modifier.overallMaximum}
    });
    const [isModifiersUpdated, setIsModifiersUpdated] = useState(false);
    const [accordionStates, setAccordionStates] = useState(states);
    const [validationIssues, updateValidationIssues] = useState(defaultValidationInfo);

    const stateReadOnlyMap = useMemo(()=> {
        return states.map((currentState) => {
            const currentOverallCreditDebit = parseFloat(stateTotalCreditDebit.find(factor => factor.state === currentState).overallCreditDebit).toFixed(2);
            const currentTotalMinimum = stateOverallMinMax.find(factor => factor.state === currentState).overallMin;
            const currentTotalMaximum = stateOverallMinMax.find(factor => factor.state === currentState).overallMax;
            let isReadOnly = false;
            if (isExternalUser) {
                // UWs will have a larger credit / debit range than an agent would (UWs can do +/- 35% in AK whereas Agents can only do +/-15%) If an UW enters a modifier sum outside of the agent allowed amount, the screen should become un-editable for agents if he opened this submission
                if(!_.isNil(modifierAK)) {
                    isReadOnly = currentOverallCreditDebit > _.get(agentStateTotalRangeMap, `${modifierAK.jurisdiction}.maximum`) 
                    || currentOverallCreditDebit < _.get(agentStateTotalRangeMap, `${modifierAK.jurisdiction}.minimum`)
                } else {
                    isReadOnly =  currentOverallCreditDebit > currentTotalMaximum || currentOverallCreditDebit < currentTotalMinimum;
                }
            }
            return {state: currentState, isCurrentStateReadOnly: isReadOnly}     
        })
    }, [])
    const writeValue = (value, path, item, state) => {
        const currentModifier = modifiers.find((modifier) => modifier.jurisdiction === state);
        const currentModifierIndex = modifiers.findIndex((modifier) => modifier.jurisdiction === state);
        const newRateFactors= _.clone(currentModifier.rateFactors).map((oldRateFactor) => {
            if (getRateFactorID(oldRateFactor) === getRateFactorID(item)) {
                // eslint-disable-next-line no-param-reassign
                oldRateFactor[path] = value;
            }
            return oldRateFactor
        });
        if(path === 'creditDebit') {
            const newTotalCreditDebit = newRateFactors.reduce((total, rateFactor) =>  total + rateFactor.creditDebit, 0);
            const stateToUpdateIndex = allStateTotalCreditDebit.findIndex(factor => factor.state === state);
            updateStateTotalCreditDebit(prev => {
                const newStateTotalCreditDebit = [...prev];
                newStateTotalCreditDebit[stateToUpdateIndex] = {state, overallCreditDebit: newTotalCreditDebit};
                return newStateTotalCreditDebit}
            )
        }
        _.set(submissionVM, `value.lobData.generalLiability.modifiers_Ext[${currentModifierIndex}].rateFactors`, newRateFactors);
    };

    const handleJustificationChange = (justification, item) => {
        if(justification !== _.get(item, 'justification')){
            setIsModifiersUpdated(true);
        }
    }

    const handlePercentageInputOnBlur = (value, item, state, path) => {
        setIsModifiersUpdated(true);
        writeValue(parseFloat(((parseFloat(value) * 100)/10000).toFixed(3)), path, item, state);
    }

    const getValidationIssues = useCallback(() => {
        let allValidationIssues = [];
        modifiers.forEach(stateInfo => {
            const overallMin = overallMinAK || stateInfo.overallMinimum;
            const overallMax = overallMaxAK || stateInfo.overallMaximum;
            const currentState = stateInfo.jurisdiction;
            const {rateFactors} = stateInfo;
            const currentTotalCreditDebit = allStateTotalCreditDebit.find(factor => factor.state === currentState).overallCreditDebit;
            const invalidRateFactors = rateFactors.filter((item) => 
                {
                    const isUnEditable = item.category === messages.unEditableCategory.defaultMessage && currentState === 'Alaska';
                    return (!isUnEditable && item.creditDebit !== 0) && (_.isNil(item.justification) || item.justification === '')
   
                });
                                    
            if (currentTotalCreditDebit < overallMin || currentTotalCreditDebit > overallMax ) {
                allValidationIssues = [...allValidationIssues, {
                    type: 'warning',
                    reason: messages.creditDebitOutOfRange.defaultMessage
                }]
            } 
            if (!_.isEmpty(invalidRateFactors)) {
                allValidationIssues = [...allValidationIssues, {
                    type: 'warning',
                    reason: messages.justificationsRequired.defaultMessage
                }]
            }
        })
        updateValidationIssues(defaultValidationInfo.concat(allValidationIssues));
        return _.uniqBy(allValidationIssues, 'reason');
    }, [allStateTotalCreditDebit, defaultValidationInfo, modifiers, overallMaxAK, overallMinAK])

    // if item is editable and filled the credit/debit, but not filled the justification, then invalid
    // if each item's credit/debit exceed it's min/max, then invalid, also, if total credit/debit exceed it's overallTotal, then invalid
    const isRateFactorsValid = useCallback(() => {
        let isValid = true;
        modifiers.forEach(stateInfo => {
            const {rateFactors} = stateInfo;
            const overallMin = overallMinAK || stateInfo.overallMinimum;
            const overallMax = overallMaxAK || stateInfo.overallMaximum;
            const currentState = stateInfo.jurisdiction;
            const currentTotalCreditDebit = allStateTotalCreditDebit.find(factor => factor.state === currentState).overallCreditDebit;
            const invalidRateFactors = rateFactors.filter((item) => 
                {
                    const isUnEditable = item.category === messages.unEditableCategory.defaultMessage && currentState === 'Alaska';
                    return(!isUnEditable && item.creditDebit !== 0) && (_.isNil(item.justification) || item.justification === '')
                });
            const invalidCreditDebitInput = rateFactors.filter( (item) => 
                {
                    return (item.creditDebit > item.maximum || item.creditDebit < item.minimum || _.isNaN(item.creditDebit))
                });
            if ((currentTotalCreditDebit < overallMin) || (currentTotalCreditDebit > overallMax) || !_.isEmpty(invalidRateFactors) || !_.isEmpty(invalidCreditDebitInput)) {
                isValid = false;
            } 
        })
        return isValid
    }, [allStateTotalCreditDebit, modifiers, overallMaxAK, overallMinAK])
    
    const onPageNext = useCallback(async () => {
        // currently, based on requirement, only consider 'Alaska'
        const isReadOnlyModeForUser = _.get(stateReadOnlyMap.find(factor => factor.state === 'Alaska'), 'isCurrentStateReadOnly', false);
        if (!isReadOnlyModeForUser && !isRateFactorsValid()) {
            getValidationIssues();
            return false;
        }
        if (isModifiersUpdated){
            setLoadingMask(true);
            const newModifiers = await GLModifierService.updateModifiers(jobID, sessionUUID, modifiers, authHeader);
            setLoadingMask(false);
            _.set(submissionVM, 'value.lobData.generalLiability.modifiers_Ext', newModifiers);

            //
            _.set(submissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);

            updateWizardData(submissionVM);
        }
        return submissionVM; 

    }, [authHeader, getValidationIssues, isModifiersUpdated, isRateFactorsValid, jobID, modifiers, sessionUUID, setLoadingMask, stateReadOnlyMap, submissionVM, updateWizardData]);

    const CreditDebitInputField = ({item, state, isUnEditable, isReadOnly}) => {
        const shownCreditDebit = _.isNaN(_.get(item, 'creditDebit')) ? null : `${parseFloat(_.get(item, 'creditDebit') * 100).toFixed(1)}%`;
        const [creditDebit, setCreditDebit] = useState(shownCreditDebit);
        const errorCondition = ((parseFloat(creditDebit) * 100)/10000 > item.maximum) || ((parseFloat(creditDebit) * 100)/10000 < item.minimum) || (_.isNaN((parseFloat(creditDebit) * 100)/10000));
        return <InputField
            onValueChange={(value) => {setCreditDebit(value)}}
            onBlur={() => {handlePercentageInputOnBlur(creditDebit, item, state, 'creditDebit')}}
            value={creditDebit}
            readOnly={isUnEditable || isReadOnly}
            showErrors
            validationMessages={errorCondition ? [messages.invalidRateFactorError] : null}
        />
    }
    
    const JustificationInputField = ({item, state, isReadOnly}) => {
        const [justification, setJustification] = useState(_.get(item, 'justification'));
        return <InputField
            onValueChange={(value) => setJustification(value)}
            onBlur={(value) => {handleJustificationChange(value, item); writeValue(justification, 'justification', item, state)}}
            readOnly={isReadOnly}
            value={justification}
        />
    }

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(_.stubTrue)}
            pageLevelValidationIssues={validationIssues}
            onNext={onPageNext}
            alwaysCallOnNext
        >
            <h3>Modifiers</h3>
            <hr/>
            {
                modifiers.map(modifier => {
                    const currentState = modifier.jurisdiction;
                    const currentOverallCreditDebit = stateTotalCreditDebit.find(factor => factor.state === currentState).overallCreditDebit;
                    const currentTotalMinimum = stateOverallMinMax.find(factor => factor.state === currentState).overallMin;
                    const currentTotalMaximum = stateOverallMinMax.find(factor => factor.state === currentState).overallMax;
                    const isReadOnly = stateReadOnlyMap.find(factor => factor.state === currentState).isCurrentStateReadOnly;
                    const newRateFactors = modifier.rateFactors.concat({category: 'Overall'});
                    return <div>
                                <h4>Modifier: Schedule Rating Modification</h4>
                                <hr/>
                                <DataTable
                                    columnsConfigurable={false}
                                    data={newRateFactors}
                                    id="basic"
                                    showPagination={false}
                                    showSearch={false}
                                    tableLabel="Modifiers"
                                    >
                                    <DisplayColumn
                                        header="Category"
                                        id="category"
                                        path="category"
                                        textAlign="left"
                                        sortable={false}
                                    />
                                    <DisplayColumn
                                        header="Maximum Credit"
                                        id="minimum"
                                        renderCell={(item, index) => {
                                            return index ===  modifier.rateFactors.length ?  
                                            (overallMinAK && `${parseInt(overallMinAK * 100)}%`) || `${parseInt(currentTotalMinimum * 100)}%` 
                                            : `${parseInt(newRateFactors[index].minimum * 100)}%`}}
                                        textAlign="left"
                                        sortable={false}
                                    />
                                    <DisplayColumn
                                        header="Maximum Debit"
                                        id="maximum"
                                        renderCell={(item, index) => {
                                            return index ===  modifier.rateFactors.length ? 
                                            (overallMaxAK && `${parseInt(overallMaxAK * 100)}%`)  || `${parseInt(currentTotalMaximum * 100)}%` 
                                            : `${parseInt(newRateFactors[index].maximum * 100)}%`}}
                                        textAlign="left"
                                        sortable={false}
                                    />
                                    <DisplayColumn
                                        header="Credit(-)/Debit(+)"
                                        id="Liability"
                                        renderCell={(item, index) => { 
                                            const isUnEditable = item.category === messages.unEditableCategory.defaultMessage && currentState === 'Alaska';
                                            return index ===  modifier.rateFactors.length ? `${parseFloat(currentOverallCreditDebit * 100).toFixed(1)}%` : <CreditDebitInputField item={item} state={currentState} isUnEditable={isUnEditable} isReadOnly={isReadOnly}/>}} 
                                        textAlign="left"
                                        sortable={false}
                                    />
                                    <DisplayColumn
                                        header="Justification"
                                        id="justification"
                                        renderCell= {(item, index) => {
                                            return index ===  modifier.rateFactors.length ? '' : <JustificationInputField item={item} state={currentState} isReadOnly={isReadOnly}/>}}
                                        textAlign="left"
                                        sortable={false}
                                    />
                                </DataTable>
                            </div>
                })
            }
        </WizardPage>
    );
}

GLModifiersPage.propTypes = WizardPage.propTypes;
GLModifiersPage.defaultProps = WizardPage.defaultProps;
export default GLModifiersPage;