import { AccordionCard,Card } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import _ from 'lodash';
import React,{ useCallback,useEffect,useState } from 'react';
import { GLCoverageService } from 'wni-capability-quoteandbind-gl';
import GLClausesComponentVM from '../../../GeneralLiability/components/GLClausesComponentVM/GLClausesComponentVM';
import GLSingleClauseComponentVM from '../../../GeneralLiability/components/GLSingleClauseComponentVM/GLSingleClauseComponentVM';
import CoverageContext from '../../../GeneralLiability/context/GLCoverageContext';
import GLCoverageUtil from '../../../GeneralLiability/util/GLCoverageUtil';
import ExposureDetails from '../ExposureDetails/ExposureDetails';
import messages from './ExposureDetailsAndCoveragesAccordionCard.messages';




const ExposureDetailsAndCoveragesAccordionCard = (props) => {
    const {
        currentRow,
        currentExposureClauses,
        setCurrentExposureClauses,
        writeValue: onValueChange,
        syncWizardData = _.noop,
        syncWizardDataSnapshot = _.noop,
        generateValidationIssues = _.noop,
        wizardData: submissionVM,
        updateWizardData,
        onValidate: onExposurePageValidate,
        showErrors,
        readOnly,
        extendProps,
        setIsEditing,
        isEditing,
    } = props

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const {
        jobID,
        sessionUUID,
    } = extendProps

    const translator = useTranslator();
    const { authHeader } = useAuthentication();

    const validationID = 'glExposureDetailsAndCoverages'

    const {
        onValidate,
        isComponentValid,
    } = useValidation(validationID)

    useEffect(() => {
        if (onExposurePageValidate) {
            onExposurePageValidate(isComponentValid, validationID)
        }
        return () => {
            if (onExposurePageValidate) {
                onExposurePageValidate(true, validationID)
            }
        }
    }, [isComponentValid, onExposurePageValidate])

    const { standardCoverage } = currentExposureClauses;

    const [loadingClauses, setLoadingClauses] = useState(false);

    const changeSubmission = useCallback(
        (value, changedPath) => {
            const newClauses = GLCoverageUtil.setClauseValue(standardCoverage, 'standardCoverage', value, changedPath);
            const newExposureClauses = _.clone(currentExposureClauses)
            _.set(newExposureClauses, 'standardCoverage', newClauses);
            setCurrentExposureClauses(newExposureClauses);
            setIsEditing(false);
            return newExposureClauses;
        },
        [standardCoverage, currentExposureClauses, setCurrentExposureClauses, setIsEditing]
    );

    const updateVMToServer = useCallback(async (newExposureClauses, setLoadingClausesFunc, setEditingFunc) => {
        const exposureClausesToUpdate = GLCoverageUtil.generateUpdatedExposureClausesDTO(newExposureClauses);
        const updatedExposureClausesPublicIDs = GLCoverageUtil.getUpdatedCoveragesCodesArrayByUpdatedExposureClausesDTO(exposureClausesToUpdate);
        setLoadingMask(true)
        setLoadingClausesFunc(updatedExposureClausesPublicIDs)
        const response = await GLCoverageService.updateExposureClauses(
            jobID,
            sessionUUID,
            exposureClausesToUpdate,
            authHeader
        )
        setLoadingClausesFunc(false)
        setLoadingMask(false)
        setEditingFunc(false)
        setCurrentExposureClauses(response);
    }, [setLoadingMask, jobID, sessionUUID, authHeader, setCurrentExposureClauses]);

    const updateNewExposureClausesToServer = useCallback(async (newSublineClauses) => {
        updateVMToServer(newSublineClauses, setLoadingClauses, setIsEditing)
    }, [updateVMToServer, setLoadingClauses, setIsEditing]);



    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            const newSublineClauses = changeSubmission(value, changedPath);
            
            updateNewExposureClausesToServer(newSublineClauses);
        },
        [changeSubmission, updateNewExposureClausesToServer]
    );

    const onSyncCoverages = useCallback(
        () => {
            updateNewExposureClausesToServer(currentExposureClauses);
        },
        [currentExposureClauses, updateNewExposureClausesToServer]
    )

    return (
        <AccordionCard
            id="exposureDetailsAndCoveragesCard"
            chevron
            title={translator(messages.detailsAndCoverages)}
            errorState={showErrors && !isComponentValid}
        >
            <Card
                title={translator(messages.details)}
                className="wizardSection pv-0"
                headerClassName="font-capitalize"
            >
                <ExposureDetails
                    currentRow={currentRow}
                    writeValue={onValueChange}
                    syncWizardData={syncWizardData}
                    syncWizardDataSnapshot={syncWizardDataSnapshot}
                    generateValidationIssues={generateValidationIssues}
                    onValidate={onValidate}
                    showErrors={showErrors}
                    readOnly={readOnly}
                    extendProps={extendProps}
                />
            </Card>
            <Card
                title={translator(messages.coverages)}
                className="formCoveragesContainer wizardSection pv-0"
                headerClassName="font-capitalize"
            >
                <CoverageContext.Provider
                    value={{
                        wizardData: submissionVM,
                        updateWizardData: updateWizardData,
                        updateVMToServer: updateVMToServer,
                    }}
                >
                    <GLClausesComponentVM
                        splitByClauseCategory={false}
                        sortBySelected
                        onSyncCoverages={onSyncCoverages}
                        onChangeSubmissionAndSync={changeSubmissionAndSync}
                        onChangeClause={changeSubmission}
                        onValidate={onValidate}
                        showAmount={false}
                        loadingClause={loadingClauses}
                        path="standardCoverage"
                        value={standardCoverage}
                        showErrors={showErrors}
                        componentMapOverrides={{
                            GLSingleClauseComponentVM: GLSingleClauseComponentVM,
                        }}
                        setIsEditing={setIsEditing}
                        isEditing={isEditing}
                        isEditable={!readOnly}
                    />
                </CoverageContext.Provider>
            </Card>
        </AccordionCard>
    );
}

export default ExposureDetailsAndCoveragesAccordionCard;
