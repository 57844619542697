/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { withDependencies } from '@xengage/gw-portals-dependency-react';
import { ViewModelForm, withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { ComponentRenderHelper as renderHelper } from 'wni-portals-util-react';
import { TranslatorContext } from '@jutro/locale';
import { CancellationService, ActivitiesService, UserService } from 'gw-capability-gateway';
import { AccountService } from 'gw-capability-gateway-policy';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
import { ActivityUtil, WniDateUtil, WniCancellationUtil, ErrorsAndWarningsUtil, WniProductsUtil } from 'wni-portals-util-js';
import { Loader, withModalContext } from '@jutro/components';
import { WniPolicyService, WniActivitiesService, WniSubmissionService } from 'wni-capability-gateway';
import metadata from './PolicyCancellation.metadata.json5';
import styles from './PolicyCancellation.module.scss';
import messagesExt from './PolicyCancellation.messages';

class CancellationWithoutModalContext extends Component {
    static propTypes = {
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired,
        onDoNotCancel: PropTypes.func.isRequired,
        policyNumber: PropTypes.string.isRequired,
        policyData: PropTypes.shape({
            policyNumber: PropTypes.string.isRequired,
            account: PropTypes.shape({
                accountNumber: PropTypes.string.isRequired
            })
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        authHeader: PropTypes.shape({
            Authorization: PropTypes.string
        }).isRequired,
        interactionModel: PropTypes.shape({
            goToPage: PropTypes.func,
        }).isRequired,
        onChangePolicy: PropTypes.func
    }

    static contextType = TranslatorContext;

    state = {
        cancellationSubmissionVM: {},
        submitted: false,
        enableEffectiveDate: true,
        refundMethodsData: [],
        selectedRefundMethod: '',
        isServiceCallInProgress: false,
        validationIssuesWarning: [],
        canStartCancellationErrors: [],
        showEffectiveDateError : false
    };

    async componentDidMount() {
        let { cancellationSubmissionVM } = this.state;

        const { policyData, authHeader } = this.props;
        const { latestPeriod: 
            {
                producerCodeOfService
            }
        } = policyData;
        const model = {};
        cancellationSubmissionVM = this.createVM(model);
        cancellationSubmissionVM.cancellationSource = _.find(
            cancellationSubmissionVM.cancellationSource.aspects.availableValues, {
                code: 'insured'
            }
        );
        const sysLocalDate = await WniSubmissionService.getSystemDate(authHeader);
        cancellationSubmissionVM = WniCancellationUtil.updateCancellationDate(policyData, cancellationSubmissionVM, sysLocalDate);
        this.setState({
            cancellationSubmissionVM
        });
        const access = await this.getPolicyAccess(producerCodeOfService);
        if (!access) {
            this.showWarningMsg();
        }; 
    };

    getPolicyAccess = async(agencyOfServiceCode) => {
        const { authUserData } = this.props
        const { producerCodes_Ext: activeProducerCodeList } = authUserData
        let result = false;
        if (!_.get(authUserData, 'isExternalUser_Ext')) {
            result = true;
        } else {
            _.forEach(activeProducerCodeList, (agency) => {
                const { code } = agency;
                if (agencyOfServiceCode === code) {
                    result = true;
                    return false;
                }
                return true;
            });
        }
        return result;
    };

    showWarningMsg = () => {
        const  { history } = this.props
        ModalNextProvider.showAlert({
            status: 'warning',
            icon: 'gw-error-outline',
            message: messagesExt.warningMsg,
        }).then(() => {
            history.push('/home');
        }).catch(() => {
            _.noop();
        });
    };

    validateEffectiveDate = async () => {
        const { cancellationSubmissionVM } = this.state;
        const {
            effectiveDate: chooseDate
        } = cancellationSubmissionVM.value;
        if (!moment(chooseDate).isValid() || _.isEmpty(chooseDate)) {
            return
        }
        const translator = this.context;
        const { authHeader, policyData, interactionModel, history } = this.props;
        const { policyNumber, account: { accountNumber } } = policyData;
        const tempCancellation = this.genCancelDTO();
        this.setState({isServiceCallInProgress: true})
        const canStartMsg = await WniPolicyService.canStartCancellation(policyNumber, chooseDate, tempCancellation, authHeader)
        if (!_.isEmpty(canStartMsg)) {
            this.setState({
                canStartCancellationErrors: [{
                    type: 'error',
                    reason: canStartMsg
                }]
            });
        } else {
            this.setState({
                canStartCancellationErrors: []
            });
        }
        this.setState({isServiceCallInProgress: false})
        const chosenEffectiveDate = moment(chooseDate);
        if (WniDateUtil.isDaysBefore(chosenEffectiveDate, 30)) {
            this.props.modalContext.showConfirm({
                title: '',
                message: translator(messagesExt.moreThanBack30Error),
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: translator(messagesExt.referToUnderwriter),
            }).then((result) => {
                if (result === 'confirm') {
                    const activityData = ActivityUtil.getActivityData(
                        translator(messagesExt.moreThanBack30Subject),
                        translator(messagesExt.beforeDaysActivityDesc, { policyNumber: `${policyNumber}` }),
                        'high', '30_days_backdated_Cancellation_Insured'
                    );
                    activityData.policyNumber = policyNumber;
                    activityData.dueDate = new Date(WniDateUtil.getDiffDateByDay(new Date(), 1));
                    activityData.escalationDate = new Date(WniDateUtil.getDiffDateByDay(new Date(), 3));
                    this.setState({isServiceCallInProgress: true})
                    WniActivitiesService.createNewActivityAssignToUW(activityData, authHeader);
                    this.setState({isServiceCallInProgress: false})
                    // Will jump to AO Policy Details page
                    interactionModel.goToPage(null, history, 'policySummary', accountNumber, policyNumber);
                }
                
            }, _.noop);
        }

    };

    writeValue = (value, path) => {
        const { cancellationSubmissionVM } = this.state;
        _.set(cancellationSubmissionVM, path, value);
        // reason and cancellationRefundMethod may effect valid effective date
        if ([
            'effectiveDate',
            'cancelReasonCode',
            'cancellationRefundMethod'
        ].includes(path)) {
            this.validateEffectiveDate(value, path);
        }
        this.setState({ cancellationSubmissionVM });
    };

    createVM = (model) => {
        const { viewModelService } = this.props;
        return viewModelService.create(model,
            'pc', 'edge.capabilities.gateway.job.cancellation.dto.CancellationDTO');
    };

    onStartCancel = async () => {
        const {
            history, authHeader, policyData, interactionModel
        } = this.props;
        const translator = this.context;
        const { policyNumber, account: { accountNumber } } = policyData;
        const { cancellationSubmissionVM, selectedRefundMethod, enableEffectiveDate } = this.state;
        const reasonCode = _.get(cancellationSubmissionVM.value, 'cancelReasonCode');
        const allCancelReasonCode = _.get(cancellationSubmissionVM.value, 'allCancelReasonCode_Ext');
        const descriptionVal = _.get(cancellationSubmissionVM, 'cancellationTextArea');
        const effectDate = _.get(cancellationSubmissionVM.value, 'effectiveDate');
        const earliestCancellationDate = _.get(cancellationSubmissionVM, 'earliestCancellationDate');

        this.setState({ showEffectiveDateError: true });
        if (enableEffectiveDate && WniCancellationUtil.formatLocalDate(effectDate).getTime() < earliestCancellationDate.getTime()) {
            return;
        }

        const withLoss = await WniPolicyService.hasLossHistory(policyNumber, effectDate, authHeader);

        const dataTo = {
            cancellationSource: 'insured',
            isTempCancellation: true,
            cancelReasonCode: reasonCode,
            allCancelReasonCode_Ext: allCancelReasonCode,
            cancellationRefundMethod: selectedRefundMethod,
            description: descriptionVal,
            cancellationWithLoss_Ext: withLoss,
            effectiveDate: effectDate
        };
        this.setState({ isServiceCallInProgress: true });

        if (reasonCode === 'other') {
            const activityData = ActivityUtil.getActivityData(
                translator(messagesExt.otherActivitySubject),
                translator(messagesExt.otherActivityDesc, { policyNumber: `${policyNumber}`, descriptionVal: `${descriptionVal}` }),
                'high', 'cancel_req_other'
            );
            activityData.policyNumber = policyNumber;
            const effectiveDateResponse = await WniPolicyService.createNewPolicyCancellationTransaction(policyNumber, dataTo, authHeader);
            const newValidationIssues = ErrorsAndWarningsUtil
                .getValidationIssues(_.get(effectiveDateResponse, 'errorsAndWarnings_Ext'));
            const hasError = newValidationIssues.some((issue) => issue.type === 'error');
            if (hasError) {
                this.setState({
                    validationIssuesWarning: newValidationIssues,
                    isServiceCallInProgress: false
                });
                return;
            }
            this.setState({ isServiceCallInProgress: false });
            await WniActivitiesService.createNewActivityAssignToPLCancellationQueue(activityData, effectDate, authHeader);
            history.push(`/cancellation/${effectiveDateResponse.jobNumber}/summary`);

            return;
        }

        if (!_.isEmpty(withLoss) && withLoss === 'Yes') {
            this.props.modalContext.showConfirm({
                title: '',
                message: translator(messagesExt.withLossWarning),
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: translator(messagesExt.referToUnderwriter),
                cancelButtonText: translator(messagesExt.back)
            }).then((result) => {
                if (result === 'cancel' || result === 'close') {
                    this.setState({ isServiceCallInProgress: false });
                    return;
                }
                WniPolicyService.createNewPolicyCancellationTransaction(policyNumber, dataTo, authHeader)
                    .then((effectiveDateResponse) => {
                        const newValidationIssues = ErrorsAndWarningsUtil
                            .getValidationIssues(_.get(effectiveDateResponse, 'errorsAndWarnings_Ext'));
                        const hasError = newValidationIssues.some((issue) => issue.type === 'error');
                        if (hasError) {
                            this.setState({
                                validationIssuesWarning: newValidationIssues,
                                isServiceCallInProgress: false
                            });
                            return;
                        }
                        const activityData = ActivityUtil.getActivityData(
                            translator(messagesExt.withLossSubject),
                            translator(messagesExt.withLossActivityDesc, { jobNumber: `${effectiveDateResponse.jobNumber}` }),
                            'high', 'Backdated_Cancellation_Loss_Insured'
                        );
                        activityData.policyNumber = effectiveDateResponse.policy.policyNumber;
                        activityData.dueDate = new Date(WniDateUtil.getDiffDateByDay(new Date(), 1));
                        activityData.escalationDate = new Date(WniDateUtil.getDiffDateByDay(new Date(), 3));
                        WniActivitiesService.createNewActivityAssignToUW(activityData, authHeader);
                        this.setState({ isServiceCallInProgress: false });
                        history.push(`/cancellation/${effectiveDateResponse.jobNumber}/summary`, effectiveDateResponse);
                        // Will jump to AO Policy Details page
                        interactionModel.goToPage(null, history, 'policySummary', accountNumber, policyNumber);
                    });
            }).catch(() => {
                this.setState({ isServiceCallInProgress: false });
            });
        } else {
            await WniPolicyService.createNewPolicyCancellationTransaction(policyNumber, dataTo, authHeader)
                .then((effectiveDateResponse) => {
                    const newValidationIssues = ErrorsAndWarningsUtil
                        .getValidationIssues(_.get(effectiveDateResponse, 'errorsAndWarnings_Ext'));
                    const hasError = newValidationIssues.some((issue) => issue.type === 'error');
                    if (hasError) {
                        this.setState({
                            validationIssuesWarning: newValidationIssues,
                            isServiceCallInProgress: false
                        });
                        return;
                    }
                    this.setState({ isServiceCallInProgress: false });
                    history.push(`/cancellation/${effectiveDateResponse.jobNumber}/summary`, effectiveDateResponse);
                });
        }
    };

    onDoNotCancel = () => {
        const { onDoNotCancel } = this.props;
        if (onDoNotCancel) {
            onDoNotCancel();
        }
    };

    getRefundMethod = async (value) => {
        if (_.isNil(value)) {
            return false;
        }
        const { authHeader, policyData } = this.props;
        const { cancellationSubmissionVM } = this.state;
        const { policyNumber } = policyData;
        const productCode = _.get(policyData, 'product.productCode');
        const isCLProduct = WniProductsUtil.isCLProduct(productCode);
        // _.set(cancellationSubmissionVM, 'cancelReasonCode', value);
        this.setState({ cancellationSubmissionVM });
        const dataTo = {
            cancellationSource: 'insured',
            isTempCancellation: true,
            // cancelReasonCode: _.get(cancellationSubmissionVM.value, 'cancelReasonCode'),
            // allCancelReasonCode_Ext: _.get(cancellationSubmissionVM.value, 'allCancelReasonCode_Ext')
        };
        if (isCLProduct) {
            _.set(cancellationSubmissionVM, 'allCancelReasonCode_Ext', value);
            _.set(dataTo, 'allCancelReasonCode_Ext', value);
        } else {
            _.set(cancellationSubmissionVM, 'cancelReasonCode', value);
            _.set(dataTo, 'cancelReasonCode', value);
        }
        const res = await CancellationService.getRefundMethods(policyNumber, dataTo, authHeader)

        this.setState({
            refundMethodsData: res,
            selectedRefundMethod: res[0]
        });
        this.getEffectiveDate(res[0]);
    }

    genCancelDTO = (selectedRefundMethod) => {
        const { cancellationSubmissionVM } = this.state;
        return {
            cancellationSource: 'insured',
            isTempCancellation: true,
            allCancelReasonCode_Ext: _.get(cancellationSubmissionVM.value, 'allCancelReasonCode_Ext'),
            cancellationRefundMethod: selectedRefundMethod,
        };
    }

    getEffectiveDate = (selectedRefundMethod) => {
        const { authHeader, policyData } = this.props;
        const { policyNumber } = policyData;
        const dataTo = this.genCancelDTO(selectedRefundMethod);
        CancellationService.getEffectiveDateForCancellation(
            policyNumber,
            dataTo,
            authHeader
        ).then((effectiveDateResponse) => {
            const effectiveDatetoDate = WniDateUtil.getDateObj(new Date(effectiveDateResponse));
            this.writeValue(effectiveDatetoDate, 'effectiveDate');
            if (!_.isEmpty(effectiveDateResponse)) {
                this.setState({
                    enableEffectiveDate: false,
                });
            } else {
                this.setState({
                    enableEffectiveDate: true,
                });
            }
        });
    }

    getMinData = (cancellationDate) => {
        const formatedDate = new Date(cancellationDate);
        formatedDate.setDate(formatedDate.getDate() - 1);
        return formatedDate;
    };

    getMaxData = (cancellationDate) => {
        const formatedDate = new Date(cancellationDate);
        formatedDate.setDate(formatedDate.getDate() + 1);
        return formatedDate;
    };

    displayRefundValues = (refundAvailableValues) => {
        const translator = this.context;
        return refundAvailableValues.map((values) => {
            return {
                code: values.code,
                name: translator({
                    id: values.name,
                    defaultMessage: values.name
                })
            };
        });
    }

    handleIsChangeMailingAddress = (value) => {
        const { cancellationSubmissionVM } = this.state;
        const { onChangePolicy } = this.props;
        _.set(cancellationSubmissionVM, 'isChangeMailingAddress', value);
        this.setState({ cancellationSubmissionVM });
        const translator = this.context;
        if (value) {
            this.props.modalContext.showConfirm({
                title: translator(messagesExt.changeAddressTitle),
                message: translator(messagesExt.changeAddressMessage),
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: translator(messagesExt.startAddressChange),
                cancelButtonText: translator(messagesExt.close)
            }).then((result) => {
                if (result === 'confirm') {
                    // start address change
                    onChangePolicy('cancellation');
                } else {
                    _.set(cancellationSubmissionVM, 'isChangeMailingAddress', false);
                    this.setState({ cancellationSubmissionVM });
                }
            }, _.noop);
        }
    }

    isShowChangeMailingAddressButton = () => {
        const { policyData } = this.props;
        const productCode = _.get(policyData, 'product.productCode');
        if (productCode === 'HOPHomeowners'
            || productCode === 'DwellingProperty'
            || productCode === 'Watercraft'
            || productCode === 'RoadTrail'
            || productCode === 'PersonalUmbrella') {
            return true;
        }
        return false;
    }

    isShowChangeMailingAddress = () => {
        const { policyData } = this.props;
        const productCode = _.get(policyData, 'product.productCode');
        return this.isShowChangeMailingAddressButton() || WniProductsUtil.isCLProduct(productCode)
    }

    renderRefundLabel = () => {
        const { policyData } = this.props;
        const productCode = _.get(policyData, 'product.productCode');
        const translator = this.context;
        if (WniProductsUtil.isCLProduct(productCode)) {
            return translator(messagesExt.CLRefundLabel)
        }
        return translator(messagesExt.PLRefundLabel)
    }

    getReasonOptions = () => {
        const { cancellationSubmissionVM } = this.state;
        const { policyData } = this.props;
        const translator = this.context;

        const productCode = _.get(policyData, 'product.productCode');
        const isCLProduct = WniProductsUtil.isCLProduct(productCode);
        let availableValues = _.get(cancellationSubmissionVM, 'cancelReasonCode.aspects.availableValues');
        const allAvailableValues = _.get(cancellationSubmissionVM, 'allCancelReasonCode_Ext.aspects.availableValues');

        if (isCLProduct) {
            availableValues = _.filter(allAvailableValues, (opt) => {
                return opt.code === 'coveragenolongerneeded' ||
                    opt.code === 'coverageplacedelsewhere' ||
                    opt.code === 'deceased' ||
                    opt.code === 'issuewithbillingplans' ||
                    opt.code === 'outofbusiness' ||
                    opt.code === 'nottaken' ||
                    opt.code === 'premiumnotcompetitive' ||
                    opt.code === 'other'
            });
        }
        const options = availableValues.map((option) => {
            if (option.code === 'outofbusiness') {
                return {
                    code: option.code,
                    name: 'Out of business/sold'
                };
            }
            return {
                code: option.code,
                name: translator({
                    id: option.name
                })
            };
        });
        return options;
    }

    render() {
        const {
            submitted, cancellationSubmissionVM,
            enableEffectiveDate, refundMethodsData,
            selectedRefundMethod,
            isServiceCallInProgress,
            validationIssuesWarning,
            canStartCancellationErrors,
            showEffectiveDateError
        } = this.state;
        const { policyData } = this.props;
        if (_.isEmpty(cancellationSubmissionVM)) {
            return null;
        }
        const translator = this.context;
        const productCode = _.get(policyData, 'product.productCode');
        const isCLProduct = WniProductsUtil.isCLProduct(productCode);

        const {
            cancelReasonCode,
            effectiveDate: chooseDate,
            allCancelReasonCode_Ext: allCancelReasonCode
            // cancellationTextArea: description,
        } = cancellationSubmissionVM.value;

        const descriptionVal = _.get(cancellationSubmissionVM, 'cancellationTextArea');

        const chosenEffectiveDate = moment(chooseDate);

        const moreThanBack30Days = WniDateUtil.isDaysBefore(chosenEffectiveDate, 30)

        const otherReasonWithoutDescription = cancelReasonCode === 'other' && _.isEmpty(descriptionVal)

        const reasonIsPleaseChoose = _.isNil(cancelReasonCode) && _.isNil(allCancelReasonCode);
        /**
         * Rules of cancelBtnDisabled:
         * 1. Waiting for service to check date is valid
         * 2. Checked date is invalid in backend
         * 3. Date before 30days(No need to check here, will check it when user edit it)
         * 4. Reason is other, but no description
         * 5. effective date is editing
         * 6. Reason is 'Please Choose'
         */
        const isStartCancelBtnDisabled = 
            isServiceCallInProgress
            || canStartCancellationErrors.length > 0
            || moreThanBack30Days
            || otherReasonWithoutDescription
            || reasonIsPleaseChoose
            || !chosenEffectiveDate.isValid()
            || _.isEmpty(chosenEffectiveDate);

        const moreThanAfter30Days = WniDateUtil.isDaysAfter(chosenEffectiveDate, 30)
            && chosenEffectiveDate.isSameOrBefore(cancellationSubmissionVM.actualExpirationDate)

        // Show warning when more than 30 days after
        const moreThanAfter30DaysWarnings = moreThanAfter30Days ? [{
            type: 'warning',
            reason: translator(messagesExt.moreThanAfter30Warning)
        }] : []

        const resolvers = {
            resolveClassNameMap: styles,
            resolveComponentMap: {
                validationissuescomponent: ValidationIssuesComponent,
                loader: Loader
            },
            resolveCallbackMap: {
                onStartCancel: this.onStartCancel,
                onDoNotCancel: this.onDoNotCancel,
                getEffectiveDate: this.getEffectiveDate,
                getRefundMethod: this.getRefundMethod,
            }
        };

        const overrides = {
            '@field': {
                showErrors: submitted,
                showOptional: true,
                showRequired: true
            },
            isChangeMailingAddress: {
                onValueChange: this.handleIsChangeMailingAddress,
                visible: this.isShowChangeMailingAddressButton()
            },
            mailingAddress: {
                visible: this.isShowChangeMailingAddress()
            },
            reasonId: {
                onValueChange: this.getRefundMethod,
                visible: !isCLProduct
            },
            CLReasonId: {
                onValueChange: this.getRefundMethod,
                availableValues: this.getReasonOptions(),
                visible: isCLProduct
            },
            refundMethodId: {
                availableValues: this.displayRefundValues(_.filter(
                    cancellationSubmissionVM.cancellationRefundMethod.aspects.availableValues,
                    (x) => {
                        return _.indexOf(refundMethodsData, x.code) !== -1;
                    }
                )),
                value: selectedRefundMethod,
                label: this.renderRefundLabel()
            },
            effectiveDateId: {
                disabled: !enableEffectiveDate,
                value: _.get(cancellationSubmissionVM.value, 'effectiveDate'),
                onValueChange: this.writeValue,
                minDate: enableEffectiveDate ? WniDateUtil.getDateObj(cancellationSubmissionVM.earliestCancellationDate) : null,
                maxDate: enableEffectiveDate ? this.getMaxData(cancellationSubmissionVM.actualExpirationDate) : null,
                readOnly: _.get(cancellationSubmissionVM.value, 'cancelReasonCode') === 'nottaken',
                showErrors: showEffectiveDateError,
            },
            startCancel: {
                disabled: isStartCancelBtnDisabled,
                content: renderHelper.getButtonContent(translator(messagesExt.startCancellation), isServiceCallInProgress),
            },
            doNotCancel: {
                disabled: isServiceCallInProgress
            },
            descriptionId: {
                visible: _.get(cancellationSubmissionVM.value, 'cancelReasonCode') === 'other'
            },
            otherReasonNotification: {
                visible: _.get(cancellationSubmissionVM.value, 'cancelReasonCode') === 'other'
            },
            dynamicInlineNotificationContainer: {
                validationIssues: validationIssuesWarning
                    .concat(canStartCancellationErrors)
                    .concat(moreThanAfter30DaysWarnings),
                // visible: validationIssuesWarning.length > 0,
                scrollToIssues: true,
            },
            startCancellationLoader: {
                visible: isServiceCallInProgress,
                showLoader: isServiceCallInProgress
            },
            policyCancellationContainer: {
                visible: !isServiceCallInProgress
            }
        };

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={cancellationSubmissionVM}
                onValueChange={this.writeValue}
                overrideProps={overrides}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        );
    }
}

// export default withViewModelService(withAuthenticationContext(withDependencies(['interactionModel'])(Cancellation)));
const Cancellation = withModalContext(CancellationWithoutModalContext);

export default withViewModelService(withAuthenticationContext(withDependencies(['interactionModel'])(Cancellation)));
