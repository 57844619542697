import { setComponentMapOverrides } from '@jutro/uiconfig';
import CPPDummyPage from './pages/Dummy/CPPDummyPage';
import CPPDummyReadOnlyPage from './pages/Dummy/CPPDummyPage.readonly';

import CPPLineSelectionPage from './pages/LineSelection/CPPLineSelectionPage';
import CPPLineSelectionReadOnlyPage from './pages/LineSelection/CPPLineSelectionPage.readonly';

import CPPLocationsPage from './pages/Locations/CPPLocationsPage';
import CPPLocationsReadOnlyPage from './pages/Locations/CPPLocationsPage.readonly';

import CPPPolicyDetailsPage from './pages/PolicyDetails/CPPPolicyDetailsPage';
import CPPPolicyDetailsReadOnlyPage from './pages/PolicyDetails/CPPPolicyDetailsPage.readonly';

import CPPQualificationPage from './pages/Qualification/CPPQualificationPage';
import CPPQualificationReadOnlyPage from './pages/Qualification/CPPQualificationPage.readonly';

import CPPModifiersPage from './pages/Modifiers/CPPModifiersPage';
import CPPModifiersReadOnlyPage from './pages/Modifiers/CPPModifiersPage.readonly';

import CPPQuotePage from './pages/Quote/CPPQuotePage';
import CPPQuoteReadOnlyPage from './pages/Quote/CPPQuotePage.readonly';

setComponentMapOverrides(
    {
        CPPQualificationPage: { component: 'CPPQualificationPage' },
        CPPQualificationReadOnlyPage: { component: 'CPPQualificationReadOnlyPage' },

        CPPPolicyDetailsPage: { component: 'CPPPolicyDetailsPage' },
        CPPPolicyDetailsReadOnlyPage: { component: 'CPPPolicyDetailsReadOnlyPage' },

        CPPLineSelectionPage: { component: 'CPPLineSelectionPage' },
        CPPLineSelectionReadOnlyPage: { component: 'CPPLineSelectionReadOnlyPage' },

        CPPDummyPage: { component: 'CPPDummyPage' },
        CPPDummyReadOnlyPage: { component: 'CPPDummyReadOnlyPage' },

        //
        CPPQuotePage: { component: 'CPPQuotePage' },
        CPPQuoteReadOnlyPage: { component: 'CPPQuoteReadOnlyPage' },

        CPPLocationsPage: { component: 'CPPLocationsPage' },
        CPPLocationsReadOnlyPage: { component: 'CPPLocationsReadOnlyPage' },

        CPPModifiersPage: { component: 'CPPModifiersPage' },
        CPPModifiersReadOnlyPage: { component: 'CPPModifiersReadOnlyPage' },
    },
    {
        CPPQualificationPage,
        CPPQualificationReadOnlyPage,

        CPPPolicyDetailsPage,
        CPPPolicyDetailsReadOnlyPage,

        CPPLineSelectionPage,
        CPPLineSelectionReadOnlyPage,

        CPPDummyPage,
        CPPDummyReadOnlyPage,

        //
        CPPQuotePage,
        CPPQuoteReadOnlyPage,

        CPPLocationsPage,
        CPPLocationsReadOnlyPage,

        CPPModifiersPage,
        CPPModifiersReadOnlyPage,
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as CPPSubmissionWizard } from './CPPSubmissionWizard';
export { default as CPPQuotePageConstants } from './pages/Quote/config/CPPQuotePage.config';
export { default as CPPQuoteIssueRenderHelper } from './pages/Quote/util/CPPQuoteIssueRenderHelper';
export { default as CPPWizardPage } from './templates/CPPWizardPage';

export {
    CPPQualificationPage,
    CPPQualificationReadOnlyPage,

    CPPPolicyDetailsPage,
    CPPPolicyDetailsReadOnlyPage,

    CPPLineSelectionPage,
    CPPLineSelectionReadOnlyPage,

    CPPDummyPage,
    CPPDummyReadOnlyPage,
    //
    CPPQuotePage,
    CPPQuoteReadOnlyPage,

    CPPLocationsPage,
    CPPLocationsReadOnlyPage,

    CPPModifiersPage,
    CPPModifiersReadOnlyPage,
};
