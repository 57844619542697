import React, {
    useContext,
    useState,
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { DisplayColumn } from '@jutro/datatable';
import { Flex } from '@jutro/layout';
import { Button } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { WniDataTable, WniCheckboxField } from 'wni-common-base-components';
import SingleClauseContext from '../../context/IMSingleClauseContext';
import IMScheduleItemReadonlyCell from './IMScheduleItemReadonlyCell';
import IMScheduleItemEditableCell from './IMScheduleItemEditableCell';
import messages from './IMScheduleTable.messages';
import IMScheduleItemEditableCovTermCell from './IMScheduleItemEditableCovTermCell';

const IMScheduleTable = (props) => {
    const {
        schedule,
    } = props

    const {
        propertyInfos = [],
        coveragePropertyInfos = [],
        scheduleItems = [],
        deserializationClass,
        readonly_Ext: scheduleReadonly,
        isComplexSchedule_Ext: isComplexSchedule,
    } = schedule

    const {
        clauseCode: code,
        isEditable,
        clausePath: clauseVMPath,
        onChangeSubmissionAndSync,
    } = useContext(SingleClauseContext)

    const [selectedScheduleItemNumbers, setSelectedScheduleItemNumbers] = useState([]);
    const translator = useTranslator();


    const scheduleNumberColumn = <DisplayColumn
        key='ScheduleNumber'
        header= 'Schedule Number'
        sortable = {false}
        textAlign = 'left'
        path = 'itemNumber'
        renderCell = {(scheduleItem) => {
            const {
                itemNumber
            } = scheduleItem
            if (!isEditable || scheduleReadonly) {
                return itemNumber
            }
            return <WniCheckboxField
                value = {selectedScheduleItemNumbers.includes(itemNumber)}
                label = {itemNumber}
                showInlineLabel
                onValueChange = {(checked) => {
                    let newSelectedScheduleItemNumbers
                    if (checked) {
                        newSelectedScheduleItemNumbers = selectedScheduleItemNumbers.concat([itemNumber])
                    } else {
                        newSelectedScheduleItemNumbers = selectedScheduleItemNumbers.filter(num => num !== itemNumber)
                    }
                    setSelectedScheduleItemNumbers(newSelectedScheduleItemNumbers)
                }}
            />
        }}
    />


    const propertyInfoColumns = propertyInfos
        .filter((propertyInfo) => propertyInfo.id !== 'AdditionalInsured')
        .map((propertyInfo) => {
            const {
                id,
                label,
            } = propertyInfo
            
            return <DisplayColumn
                key = {id}
                header = {label}
                renderCell = {(scheduleItem) => {
                    const {
                        itemData: {
                            [id]: {
                                editable_Ext: isScheduleItemFieldEditable,
                                available_Ext: isScheduleItemFieldAvailable,
                            } = {}
                        },
                        itemNumber,
                    } = scheduleItem;
                    const isCellEditable = isEditable && !scheduleReadonly && isScheduleItemFieldEditable && isScheduleItemFieldAvailable
                    if (isCellEditable && !isComplexSchedule) {
                        const scheduleItemIndex = scheduleItems.findIndex((item) => {
                            return item.itemNumber === itemNumber
                        })
                        const scheduleItemVMPath = `${clauseVMPath}.schedule.scheduleItems.children[${scheduleItemIndex}]`
                        return <IMScheduleItemEditableCell
                            propertyInfo={propertyInfo}
                            scheduleItem={scheduleItem}
                            scheduleItemIndex={scheduleItemIndex}
                            scheduleItemVMPath={scheduleItemVMPath}
                        />
                    }
                    return <IMScheduleItemReadonlyCell
                        propertyInfo={propertyInfo}
                        scheduleItem={scheduleItem}
                    />
                }}
                sortable = {false}
            />
        })

    const termPropertyColumns = coveragePropertyInfos
        .sort((a, b) => a.order - b.order)
        .map(coveragePropertyInfo => {
            const {
                id,
                label,
            } = coveragePropertyInfo
            
            return <DisplayColumn
                key = {id}
                header = {label}
                renderCell = {(scheduleItem) => {
                    const {
                        itemNumber,
                        scheduleItemTerms
                    } = scheduleItem
                    const term = scheduleItemTerms.find(t => t.code_Ext === id)
                    const isCellEditable = isEditable && !scheduleReadonly

                    if (isCellEditable) {
                        const scheduleItemIndex = scheduleItems.findIndex((item) => {
                            return item.itemNumber === itemNumber
                        })
                        const scheduleItemPath = `${clauseVMPath}.schedule.scheduleItems.[${scheduleItemIndex}]`
                        return <IMScheduleItemEditableCovTermCell
                            term={term}
                            scheduleItem={scheduleItem}
                            scheduleItemPath={scheduleItemPath}
                            scheduleItemIndex={scheduleItemIndex}
                        />
                    }
                    return term.chosenTermValue
                }}
                sortable = {false}
            />
        })
    
    
    const sortedScheduleItems = scheduleItems.toSorted((scheduleItemA, scheduleItemB) => {
        const getScheduleNumber = (scheduleItem) => {
            return _.get(scheduleItem, 'itemNumber')
        }
        return getScheduleNumber(scheduleItemA) - getScheduleNumber(scheduleItemB)
    })

    const delSchedule = () => {
        const newScheduleItems = scheduleItems.filter((scheduleItem) => {
            const {
                itemNumber
            } = scheduleItem
            return !selectedScheduleItemNumbers.includes(itemNumber)
        })
        onChangeSubmissionAndSync(newScheduleItems, `${clauseVMPath}.schedule.scheduleItems`)
        setSelectedScheduleItemNumbers([])
    }

    const addSchedule = () => {
        const newScheduleItems = scheduleItems.concat([{
            '@deserialization-class': deserializationClass,
            itemData: {
            }
        }])
        onChangeSubmissionAndSync(newScheduleItems, `${clauseVMPath}.schedule.scheduleItems`)
    }


    return <>
        {
            isEditable && !scheduleReadonly && <Flex
                gap = "small"
                justifyContent = "right"
                className = "mb-10"
            >
                <Button
                        className = "wni-button-danger"
                        type = "filled"
                        disabled = {selectedScheduleItemNumbers.length === 0}
                        onClick = {delSchedule}
                    >
                        {translator(messages.scheduleDel)}
                </Button>
                <Button
                        icon = "gw-add"
                        onClick = {addSchedule}
                    >{translator(messages.scheduleAdd)}
                    </Button>
            </Flex>
        }
        <div className='table-wrapper mb-10'>
            <WniDataTable
                id = {`clause_schedule_table_${code}`}
                data={sortedScheduleItems}
                showSearch={false}
            >
                {[scheduleNumberColumn, ...propertyInfoColumns, ...termPropertyColumns]}
            </WniDataTable>
        </div>
    </>
}

IMScheduleTable.propTypes = {
    schedule: PropTypes.shape({
        propertyInfos: PropTypes.arrayOf(PropTypes.shape({}))
    }).isRequired,
    showCovTermPropertyInfo: PropTypes.bool
}

IMScheduleTable.defaultProps = {
    schedule: {
        propertyInfos: []
    },
    showCovTermPropertyInfo: false
}

export default IMScheduleTable