import { CheckboxField } from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import {
    ViewModelForm,
    ViewModelServiceContext,
} from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import { WizardConstants } from 'wni-portals-config-js';
import React, { useContext, useState, useEffect } from 'react';
import { CPPLineSelectionService } from 'wni-capability-quoteandbind-cpp';
import { QuoteUtil, WniProductsUtil, ValidationIssueUtil, WindowUtil, WizardStepUtil } from 'wni-portals-util-js';
import WizardPage from '../../templates/CPPWizardPage';
import metadata from './CPPLineSelectionPage.metadata.json5';

const LINE_SELECTION_PATH = 'lobData.commercialPackage.coverables.lineSelection';
const policyLinePatterns = 'policyLinePatterns';

function CPPLineSelctionPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardSnapshot,
        isReadOnly,
        isChangeFlow,
        toggleWizardPageDisplay,
        markFollowingWizardStepsUnvisited,
        steps
    } = props;
    const {
        jobID,
        sessionUUID,
    } = submissionVM.value;

    const { authHeader } = useAuthentication();
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const viewModelService = useContext(ViewModelServiceContext);
    const {
        initialValidation,
        onValidate,
        isComponentValid
    } = useValidation('CPPLineSelctionPage');

    const vm = _.get(submissionVM, LINE_SELECTION_PATH);
    const [lineSelectionVM, updateLineSelectionVM] = useState(vm);
    const [showErrors, updateShowErrors] = useState(false);
    const [validationIssues, updateValidationIssues] = useState([]);
    const [displayWarnings, updateDisplayWarnings] = useState(false);
   
    useEffect(() => {
        if(!viewModelService || isReadOnly) {
            return;
        };
        const { _dtoName, _xCenter } = vm;
        const newVM = viewModelService.create({...vm.value, isSave: true}, _xCenter, _dtoName);
        updateLineSelectionVM(newVM);
    }, [viewModelService]);

    const handleValidation = () => {
        updateShowErrors(true);
    };

    const generateValidationIssues = (issues) => {
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(issues);
        updateValidationIssues(newValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }
        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            updateShowErrors(true);
            return false;
        }
        return true;
    };

    const onPageNext = async() => {
        if (!isComponentValid) {
            handleValidation();
            return false;
        }
        setLoadingMask(true);
        const res = await CPPLineSelectionService.onPageNext(jobID, sessionUUID, lineSelectionVM.value, authHeader);
        setLoadingMask(false);

        const isPageValid = generateValidationIssues(res.errorsAndWarnings);
        if(!isPageValid) {
            return false;
        }
        // skip unmanned Airfact page or not  GLUnmannedAircraftPage
        const isUnmannedAircraftAvailable = _.get(res.lobData, 'generalLiability.isUnmannedAircraftAvailable', false);
        toggleWizardPageDisplay([
            {[WizardConstants.stepId]: 'GLUnmannedAircraftPage', [WizardConstants.stepVisible]: isUnmannedAircraftAvailable}
        ]);
        _.set(submissionVM.value, 'lobData', res.lobData);
        return submissionVM;
    };

    const handleSelected = async (value, path, item) => {
        const lineSelectionData = _.get(lineSelectionVM.value, policyLinePatterns, []);

        const currentIndex = lineSelectionData.findIndex((line) => item.publicId === line.publicId);
        const currentLineSelection = lineSelectionData[currentIndex];
        const newVM = viewModelService.clone(lineSelectionVM);
        const newCurrentData = {
            ...currentLineSelection,
            [path]: value,
        };
        _.set(newVM.value, `${policyLinePatterns}[${currentIndex}]`, newCurrentData);
        updateLineSelectionVM(newVM);
        setLoadingMask(true);
        const requestData = {
            ...newVM.value,
            isSave: false
        };
        const res = await CPPLineSelectionService.onCheckboxChange(jobID, sessionUUID, requestData, authHeader);
        setLoadingMask(false);
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM.value, 'lobData', res.lobData);
        updateWizardSnapshot(newSubmissionVM);

        // skip lines or not(like: CP, IM, GL, CR)
        const itemLobName = WniProductsUtil.policyLinePatternForCPP?.find((v) => v.policyLinePattern === item.publicId)?.lobName;
        if(!itemLobName) {
            return
        };
        const isSkipQualification = WizardStepUtil.skipQualificationPageForCPP(_.get(newVM.value, policyLinePatterns, []));
        const parentId = `CPP_${_.upperFirst(itemLobName)}`;

        toggleWizardPageDisplay([
            {[WizardConstants.stepId]: 'CPPQualificationPage', [WizardConstants.stepVisible]: !isSkipQualification},
            {[WizardConstants.parentId]: parentId, [WizardConstants.stepVisible]: value}
        ]);
        markFollowingWizardStepsUnvisited();
    };

    const renderCheckedCell = (item, index, { path }) => {
        const isDisabled = isReadOnly || !item.editable;
        return (
            <CheckboxField
                onValueChange={(val) => handleSelected(val, path, item)}
                disabled={isDisabled}
                value={item[path]}
            />
        );
    };

    const writeValue = (value, path) => {
        const newVM = viewModelService.clone(lineSelectionVM);
        _.set(newVM.value, path, value);
        updateLineSelectionVM(newVM);
    }

    const overrideProps = {
        '@all': {
            readOnly: isReadOnly
        },
        '@field': {
            showRequired: true,
            showOptional: false
        }
    };

    const resolvers = {
        callbackMap: {
            renderCheckedCell,
        },
        componentMap: {},
    };
    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={onPageNext}
            pageLevelValidationIssues={validationIssues}
            alwaysCallOnNext={!isReadOnly}
            showRequiredInfoForFasterQuote
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={lineSelectionVM}
                onValueChange={writeValue}
                overrideProps={overrideProps}
                showErrors={showErrors}
                onValidationChange={onValidate}
                {...resolvers}
            />
        </WizardPage>
    );
}

export default CPPLineSelctionPage;
