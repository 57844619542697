import React, {
} from 'react';
import WizardPage from '../../templates/IMWizardPage';

// import PAQuoteUIHelper from './util/PAQuoteUIHelper';
import IMAccountsReceivablePage from './IMAccountsReceivablePage';

function IMDummyReadOnlyPage(props) {

    return <IMAccountsReceivablePage {...props}/>;
}

IMDummyReadOnlyPage.propTypes = WizardPage.propTypes;
export default IMDummyReadOnlyPage;