/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { withDependencies } from '@xengage/gw-portals-dependency-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { Loader, formatDate , withModalContext} from '@jutro/components';
import { withViewModelService, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import {
    WniPolicyService, WniCancellationService, WniCustomCancellationService, WniActivitiesService
} from 'wni-capability-gateway';
import { TranslatorContext } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ActivityUtil, DocumentsUtil, WniDateUtil, WniProductsUtil, WniCancellationUtil } from 'wni-portals-util-js';
import { CancellationService, ActivitiesService, UserService } from 'gw-capability-gateway';
import gatewayMessages from 'gw-capability-gateway-react/gateway.messages';
import { SuccessNotification } from 'gw-components-platform-react';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
import { DocumentCheckedComponent } from 'wni-capability-gateway-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { useBusinessData } from 'wni-portals-util-react';
import messages from 'gw-capability-gateway-react/Cancellation/Cancellation.messages';
import BackLinkComponent from '../../Components/BackLinkComponent/BackLinkComponent';
import metadata from './CancellationSummary.metadata.json5';
import messagesExt from '../Cancellation.messages';
import messageCancelExt from '../../Policies/Cancellation/PolicyCancellation.messages';
import styles from './CancellationSummary.module.scss';

const { getFeatureCodeValue } = useBusinessData();
const enablePOI53227GoLiveFeatureForPE = getFeatureCodeValue('EnablePOI53227GoLiveFeatureForPE');

class CancellationSummaryWithoutModalContext extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired,
        authHeader: PropTypes.shape({
            Authorization: PropTypes.string
        }).isRequired,
        authUserData: PropTypes.shape({
            userType: PropTypes.string.isRequired
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        fromAccountLanding: PropTypes.shape({
            quoteDetailsData: PropTypes.shape({
                jobNumber: PropTypes.string.isRequired,
                loadCancellationSummary: PropTypes.shape({}).isRequired,
                updateJobSummary: PropTypes.func.isRequired
            })
        }).isRequired,
        interactionModel: PropTypes.shape({
            goToPage: PropTypes.func
        }).isRequired
    }

    state = {
        isLoading: false,
        reasonCodeList: [],
        selectedReasonCode: '',
        //cancelDescription: '',
        isReadOnly: true,
        cancellationWithLoss: '',
        showRescindBtn: false,
        documentsData: [],
        canQuote: true,
        validationIssuesWarning: [],
        canEffectiveDateQuoteCancel: true,
        showEffectiveDateValidation: false
    };

    componentDidMount() {
        let {
            reasonCodeList,
            selectedReasonCode,
            cancelDescription,
            cancellationWithLoss
        } = this.state;
        const translator = this.context;
        const { viewModelService } = this.props;
        const {
            fromAccountLanding: {
                quoteDetailsData: {
                    loadCancellationSummary: cancellationVM
                }
            }
        } = this.props;
        const model = {};
        const cancellationSubmissionVM = viewModelService.create(model, 'pc', 'edge.capabilities.gateway.job.cancellation.dto.CancellationDTO');
        cancellationSubmissionVM.cancellationSource = _.find(
            cancellationSubmissionVM.cancellationSource.aspects.availableValues, {
                code: 'insured'
            }
        );
        const reasons = _.get(cancellationSubmissionVM, 'cancelReasonCode.aspects.availableValues');
        reasonCodeList = reasons.map((value) => {
            return {
                code: value.code,
                name: translator({
                    id: `typekey.ReasonCode.${value.code}`,
                    defaultMessage: value.code
                })
            };
        });
        selectedReasonCode = _.get(cancellationVM, 'cancelReasonCode');
        cancelDescription = _.get(cancellationVM, 'description');
        cancellationWithLoss = _.get(cancellationVM, 'cancellationWithLoss_Ext');
        this.setState({
            reasonCodeList,
            selectedReasonCode,
            cancelDescription,
            cancellationWithLoss
        });
        this.updateReadOnly(cancellationVM);
        this.getShowRescindButton();
        this.getDocuments();
        this.addToRecentlyView();
    }

    handleError = (title, message) => {
        return this.props.modalContext.showAlert({
            title: title,
            message: message,
            status: 'error',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok
        });
    }

    addToRecentlyView = async () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: {
                    jobNumber
                }
            },
            authHeader,
        } = this.props;
        await WniCancellationService.addRecentlyViewedCancellation(
            jobNumber, authHeader
        );
    };

    getDocuments = async () => {
        try {
            const {
                fromAccountLanding: {
                    quoteDetailsData: { loadCancellationSummary: submission },
                },
                authHeader,
            } = this.props;

            const { jobNumber } = submission;
            const getAvailableDocumentsData = await WniCustomCancellationService
                .getDocumentsForCancellationGroupByType(
                    jobNumber, authHeader
                );
            const cancelDocs = DocumentsUtil
                .getDocumentData(getAvailableDocumentsData, DocumentsUtil.CANCELLATION);
            this.setState({
                retainInAgency: cancelDocs
            });
        } catch (e) {
            this.handleError(
                gatewayMessages.modalError,
                gatewayMessages.errorLoadingDocument
            );
        }
    };

    getShowRescindButton = async () => {
        const { authHeader } = this.props;
        const permissionDTO = {
            permission: 'cancelrescind'
        };

        // POI-53940 Agents cannot rescind cancellation transactions through ProducerEngage
        // For Commercial line Only
        const {
            fromAccountLanding: {
                quoteDetailsData: {
                    loadCancellationSummary: cancellationVM
                }
            }
        } = this.props;
        const productCode = _.get(cancellationVM, 'productCode');
        if (WniProductsUtil.isCLProduct(productCode)) {
            const showRescindBtn = false;
            this.setState({ showRescindBtn });
        } else {
            const showRescindBtn = await
            UserService.hasUserSystemPermission(permissionDTO, authHeader);
            this.setState({ showRescindBtn });
        }
    }

    updateReadOnly = (cancellation) => {
        this.setState({
            isReadOnly: cancellation.status !== 'Draft' || false
        });
    }

    getCancellationStatus = (status) => {
        const translator = this.context;
        return translator({ id: `typekey.PolicyPeriodStatus.${status}`, defaultMessage: status });
    }

    getCancellationSummary = (cancellation) => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { updateJobSummary }
            }
        } = this.props;
        // const cancellation = await CancellationService.findJobByJobNumber(jobNumber, authHeader);
        this.updateReadOnly(cancellation);
        updateJobSummary(cancellation);
    }

    validateEffectiveDate = async (chooseDate) => {
        if (!moment(chooseDate).isValid()) {
            return;
        }
        this.setState({
            canQuote: true,
            validationIssuesWarning: []
        });
        const {
            interactionModel,
            fromAccountLanding: {
                quoteDetailsData: {
                    loadCancellationSummary: cancellationVM
                }
            }, authHeader, history
        } = this.props;
        const translator = this.context;
        const { policy: { policyNumber, account: { accountNumber } } } = cancellationVM;
        const chosenEffectiveDate = moment(chooseDate);
        const tempCancellation = this.genCancelDTO();
        const canStartMsg = await WniPolicyService
            .canStartCancellation(policyNumber, chooseDate, tempCancellation, authHeader);
        if (!_.isEmpty(canStartMsg)) {
            this.setState({
                validationIssuesWarning: [{
                    type: 'error',
                    reason: canStartMsg
                }],
                canEffectiveDateQuoteCancel: false,
            });
            return;
        }
        this.setState({ canEffectiveDateQuoteCancel: true, showEffectiveDateValidation: false });
        if (WniDateUtil.isDaysAfter(chosenEffectiveDate, 30) && chosenEffectiveDate.isSameOrBefore(cancellationVM.actualExpirationDate)) {
            this.setState({
                validationIssuesWarning: [{
                    type: 'warning',
                    reason: translator(messageCancelExt.moreThanAfter30Warning)
                }]
            });
            return;
        }
        const withLoss = await WniPolicyService.hasLossHistory(policyNumber, chooseDate, authHeader);
        if (withLoss === 'Yes') {
            this.setState({
                validationIssuesWarning: [{
                    type: 'warning',
                    reason: translator(messagesExt.withLossWarningMessage)
                }]
            });
        }
        this.setState({ cancellationWithLoss: withLoss });

        if (WniDateUtil.isDaysBefore(chosenEffectiveDate, 30)) {
            this.setState({ canQuote: false });
            this.props.modalContext.showConfirm({
                title: '',
                message: translator(messageCancelExt.moreThanBack30Error),
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: translator(messageCancelExt.referToUnderwriter),
            }).then((result) => {
                if (result === 'cancel' || result === 'close') {
                    return;
                }
                const activityData = ActivityUtil.getActivityData(policyNumber,
                    translator(messageCancelExt.moreThanBack30Subject),
                    translator(messageCancelExt.beforeDaysActivityDesc, { policyNumber: `${policyNumber}` }),
                    'high', '30_days_backdated_Cancellation_Insured');
                activityData.policyNumber = policyNumber;
                activityData.dueDate = new Date(WniDateUtil.getDiffDateByDay(new Date(), 1));
                activityData.escalationDate = new Date(WniDateUtil.getDiffDateByDay(new Date(), 3));
                WniActivitiesService.createNewActivityAssignToUW(activityData, authHeader);
                // Will jump to AO Policy Details page
                interactionModel.goToPage(null, history, 'policySummary', accountNumber, policyNumber);
            }, _.noop);
        }
    };

    onQuoteCancellation = async () => {
        const {
            interactionModel,
            fromAccountLanding: {
                quoteDetailsData: {
                    loadCancellationSummary: cancellationVM,
                    jobNumber
                }
            }, authHeader, history,
        } = this.props;
        const translator = this.context;
        const { policy: { policyNumber, account: { accountNumber } } } = cancellationVM;
        const effectDate = _.get(cancellationVM, 'effectiveDate');
        const {
            selectedReasonCode,
            cancelDescription,
            cancellationWithLoss,
            canEffectiveDateQuoteCancel,
        } = this.state;

        const dataTo = {
            cancellationSource: 'insured',
            isTempCancellation: true,
            allCancelReasonCode_Ext: selectedReasonCode,
            cancellationRefundMethod: cancellationVM.cancellationRefundMethod,
            description: cancelDescription,
            cancellationWithLoss_Ext: cancellationWithLoss,
            effectiveDate: effectDate
        };
        if (canEffectiveDateQuoteCancel === false) {
            this.setState({ showEffectiveDateValidation: true });
            return;
        }

        if (!_.isEmpty(cancellationWithLoss) && cancellationWithLoss === 'Yes') {
            this.props.modalContext.showConfirm({
                title: '',
                message: messagesExt.withLossWarning,
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: messagesExt.referToUnderwriter,
                cancelButtonText: messagesExt.back
            }).then((result) => {
                if (result === 'cancel' || result === 'close') {
                    return;
                }
                const activityData = ActivityUtil.getActivityData(policyNumber,
                    translator(messagesExt.withLossSubject),
                    translator(messagesExt.withLossActivityDesc, { policyNumber: `${policyNumber}` }),
                    'high', 'Backdated_Cancellation_Loss_Insured');
                activityData.policyNumber = policyNumber;
                activityData.dueDate = new Date(WniDateUtil.getDiffDateByDay(new Date(), 1));
                activityData.escalationDate = new Date(WniDateUtil.getDiffDateByDay(new Date(), 3));
                WniActivitiesService.createNewActivityAssignToUW(activityData, authHeader);

                this.doQuoteCancellation(jobNumber, dataTo, authHeader);
                // Will jump to AO page
                interactionModel.goToPage(null, history, 'policySummary', accountNumber, policyNumber);
            }, _.noop);
        } else {
            const earliestCancellationDate = _.get(cancellationVM, 'earliestCancellationDate');
            if (WniCancellationUtil.formatLocalDate(effectDate).getTime() < earliestCancellationDate.getTime()) {
                return;
            }
            this.doQuoteCancellation(jobNumber, dataTo, authHeader);
        }
    }

    doQuoteCancellation = async (jobNumber, dataTo, authHeader) => {
        this.setState({ isLoading: 'continueTransaction' }); // continueTransaction
        const cancellation = await WniPolicyService.quotePolicyCancellationTransaction(jobNumber, dataTo, authHeader);
        this.getCancellationSummary(cancellation);
        this.setState({ isLoading: false });
    }

    onEditCancellation = async () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: {
                    jobNumber,
                    updateJobSummary
                }
            }, authHeader
        } = this.props;
        this.setState({ isLoading: 'editTransaction' }); // editTransaction
        const cancellation = await WniPolicyService.editPolicyCancellationTransaction(jobNumber, authHeader);
        updateJobSummary(cancellation);
        this.updateReadOnly(cancellation);
        this.setState({ isLoading: false });
    }

    onRescindCancellation = () => {
        const translator = this.context;
        const {
            fromAccountLanding: {
                quoteDetailsData: {
                    jobNumber
                }
            }, authHeader
        } = this.props;

        this.props.modalContext.showConfirm(
            {
                title: translator(messagesExt.confirmRescind),
                message: translator(messagesExt.rescindConfirmMessage),
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: translator(messagesExt.yes),
                cancelButtonText: translator(messagesExt.doNotRescind)
            }
        ).then((results) => {
            if (results === 'confirm') {
                this.setState({ isLoading: 'rescindTransaction' }); // rescindTransaction
                WniCancellationService.rescindCancellation(jobNumber, authHeader)
                    .then(
                        (cancellation) => {
                            this.getCancellationSummary(cancellation);
                            this.setState({ isLoading: false });
                        },
                        () => {
                            this.props.modalContext.showAlert({
                                title: gatewayMessages.modalError,
                                message: messagesExt.failedQuoteCancellation,
                                status: 'error',
                                icon: 'gw-error-outline',
                                confirmButtonText: commonMessages.OK
                            });
                        }
                    );
                return true;
            } else {
                return _.noop();
            } 
        }, _.noop);
    }

    onBindCancellation = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: {
                    loadCancellationSummary: cancellationVM
                }
            }, authHeader
        } = this.props;
        this.setState({ isLoading: 'submitTransaction' }); // submitTransaction
        CancellationService.bindCancellation(cancellationVM.jobNumber, authHeader)
            .then(
                (cancellation) => {
                    this.getCancellationSummary(cancellation);
                    this.setState({ isLoading: false });
                },
                () => {
                    this.props.modalContext.showAlert({
                        title: gatewayMessages.modalError,
                        message: messages.failedToBindCancellation,
                        status: 'error',
                        icon: 'gw-error-outline',
                        confirmButtonText: commonMessages.OK
                    });
                }
            );
    }

    checkCancellationFromPE = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: {
                    loadCancellationSummary: cancellationVM
                }
            }, authHeader
        } = this.props;
        this.setState({ isLoading: true });
        WniCancellationService.checkCancellationFromPE(cancellationVM.jobNumber, authHeader)
            .then(
                (cancellation) => {
                    this.getCancellationSummary(cancellation);
                    this.setState({ isLoading: false });
                },
                () => {
                    this.props.modalContext.showAlert({
                        title: gatewayMessages.modalError,
                        message: messages.failedToBindCancellation,
                        status: 'error',
                        icon: 'gw-error-outline',
                        confirmButtonText: commonMessages.OK
                    });
                }
            );
    }

    onCancelNow = () => {
        const translator = this.context;
        const {
            fromAccountLanding: {
                quoteDetailsData: {
                    loadCancellationSummary: cancellationVM
                }
            }
        } = this.props;
        const productCode = _.get(cancellationVM, 'productCode');
        const isCL = WniProductsUtil.isCLProduct(productCode);
        const confirmObj = {
            title: translator(messagesExt.confirmCancellation),
            message: isCL ? translator(messagesExt.cancelConfirmMessageCL) : translator(messagesExt.cancelConfirmMessagePL),
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: translator(messagesExt.yes),
            cancelButtonText: translator(messagesExt.doNotIssue)
        };
        this.props.modalContext.showConfirm(confirmObj).then((results) => {
            if (results === 'confirm') {
                if (isCL) {
                    this.checkCancellationFromPE();
                } else {
                    this.onBindCancellation();
                }
                return true;
            }
            return _.noop();
        }, _.noop);
    }

    onScheduleCancel = () => {
        const translator = this.context;
        const {
            fromAccountLanding: {
                quoteDetailsData: {
                    loadCancellationSummary: cancellationVM
                }
            }, authHeader
        } = this.props;
        const effectDate = _.get(cancellationVM, 'effectiveDate');
        const toDate = new Date(effectDate.year, effectDate.month, effectDate.day);
        const monEffectDate = WniDateUtil.formatDateWithPattern(toDate);

        const confirmObj = {
            title: translator(messagesExt.confirmCancellation),
            message: `${translator(messagesExt.scheduleConfirmMessage)} ${monEffectDate}?`,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: translator(messagesExt.yes),
            cancelButtonText: translator(messagesExt.doNotIssue)
        };

        this.props.modalContext.showConfirm(confirmObj).then((results) => {
            if (results === 'confirm') {
                // this.setState({ isLoading: true });
                WniCancellationService.scheduleCancellation(cancellationVM.jobNumber, toDate, authHeader)
                .then(
                    (cancellation) => {
                        this.getCancellationSummary(cancellation);
                        // this.setState({ isLoading: false });
                    },
                    () => {
                        this.props.modalContext.showAlert({
                            title: gatewayMessages.modalError,
                            message: messages.failedToBindCancellation,
                            status: 'error',
                            icon: 'gw-error-outline',
                            confirmButtonText: commonMessages.OK
                        });
                    }
                );
            return true;
            } else {
                return _.noop();
            }
        }, _.noop);
    }

    onWithdrawCancellation = () => {
        const translator = this.context;
        const {
            fromAccountLanding: {
                quoteDetailsData: {
                    loadCancellationSummary: cancellationVM
                }
            }, authHeader
        } = this.props;
        this.props.modalContext.showConfirm({
            title: translator(messagesExt.deleteCancellation),
            message: translator(messagesExt.wantDeleteCancellation),
            iconClassType: false,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: translator(messagesExt.deleteCancellation),
            cancelButtonText: translator(messagesExt.doNotDelete)
        }).then((results) => {
            if (results === 'confirm') {
                this.setState({ isLoading: 'withdrawTransaction' }); // withdrawTransaction
                WniCancellationService
                    .withdrawCancellationByCancellationNumber(cancellationVM.jobNumber, authHeader)
                    .then(
                        (cancellation) => {
                            this.getCancellationSummary(cancellation);
                            this.setState({ isLoading: false });
                        },
                        () => {
                            this.props.modalContext.showAlert({
                                title: gatewayMessages.modalError,
                                message: messagesExt.failedDeleteCancellation,
                                status: 'error',
                                icon: 'gw-error-outline',
                                confirmButtonText: commonMessages.OK
                            });
                        }
                    );
                return true;
            }
            return _.noop();
        }, _.noop);
    }

    referredUWACancellation = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: {
                    loadCancellationSummary: cancellationVM
                }
            }
        } = this.props;
        const { underwritingIssues } = cancellationVM;
        const clcheckCancellationFromPEUWI = _.find(underwritingIssues, (UWI) => UWI.issueTypeCode_Ext === 'clcheckCancellationFromPE_Ext')
        return Boolean(clcheckCancellationFromPEUWI && cancellationVM.status === this.getCancellationStatus('Quoted'));
    }

    getNotificationContent = () => {
        const translator = this.context;
        const {
            fromAccountLanding: {
                quoteDetailsData: {
                    loadCancellationSummary: cancellationVM
                }
            }
        } = this.props;
        const content = {};
        const formatDatePattern = WniDateUtil.formatDateWithPattern(cancellationVM.effectiveDate);
        const productCode = _.get(cancellationVM, 'productCode');
        const isCL = WniProductsUtil.isCLProduct(productCode);

        if (cancellationVM.status === this.getCancellationStatus('Draft')) {
            content.infoMessageTitle = translator(messagesExt.cancellationHasBeenDraft);
        } else if (cancellationVM.status === this.getCancellationStatus('Withdrawn')) {
            content.infoMessageTitle = translator(messagesExt.cancellationHasBeenWithDrawn);
            content.infoMessageDescription = translator(
                messages.needToCancelStartNewCancellation
            );
        } else if (this.referredUWACancellation()) {
            content.infoMessageTitle = translator(messagesExt.cancellationReferredUWA);
        } else if (cancellationVM.status === this.getCancellationStatus('Bound')) {
            if (cancellationVM.underwritingResponse_Ext === 'accept') {
                content.infoMessageTitle = translator(messagesExt.managerApprove);
            } else {
                content.infoMessageTitle = translator(messages.cancellationHasBeenWithBound);
                content.infoMessageDescription = translator(messages.needReinitiateContactUnderWritter);
            }
        } else if (cancellationVM.status === this.getCancellationStatus('Canceling')) {
            content.infoMessageTitle = `${translator(messagesExt.cancellationHasBeenWithCanceling)} ${formatDatePattern}`;
        } else if (cancellationVM.status === this.getCancellationStatus('Rescinded')) {
            content.infoMessageTitle = translator(messagesExt.hasBeenRescinded);
        } else if (cancellationVM.status === this.getCancellationStatus('Quoted') && cancellationVM.cancellationWithLoss_Ext === 'Yes') {
            content.infoMessageTitle = translator(messagesExt.needManagerApproval);
            if (cancellationVM.underwritingResponse_Ext === 'decline') {
                content.infoMessageTitle = translator(messagesExt.managerDeclined);
            }
        } else if (cancellationVM.status === this.getCancellationStatus('Quoted') && cancellationVM.underwritingResponse_Ext === 'decline') {
            content.infoMessageTitle = translator(messagesExt.managerDeclined);
        } else {
            content.infoMessageTitle = isCL ? translator(messagesExt.cancellationAmountToBeCalculatedCL) : translator(messagesExt.cancellationAmountToBeCalculatedPL);
            content.infoMessageDescription = translator(messagesExt.canNotBindOrWithDrawButCanAddInfo);
            if (cancellationVM.cancelReasonCode === 'nottaken') {
                content.infoMessageDescription = translator(messagesExt.canSubmitOrDelete);
            }
        }
        const infoMessages = [{
            infoMessageTitle: content.infoMessageTitle,
            infoMessageDescription: content.infoMessageDescription,
        }];

        return {
            infoMessages: infoMessages,
            continueContent: translator(messagesExt.quoteCancellation),
            withDrawContent: translator(messagesExt.deleteCancellation),
            submitContent: translator(messagesExt.submitCancellation),
            editContent: translator(messagesExt.editCancellation),
            rescindContent: translator(messagesExt.rescindCancellation)
        };
    }

    getCancellationToProceed = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: {
                    loadCancellationSummary: cancellationVM
                }
            }
        } = this.props;

        const productCode = _.get(cancellationVM, 'productCode');
        const isCL = WniProductsUtil.isCLProduct(productCode);
        if (isCL && !enablePOI53227GoLiveFeatureForPE) {
            return {
                isContinueTransaction: false,
                isWithdrawTransaction: false,
                isSubmitTransaction: false,
                isEditTransaction: false,
                isRescindTransaction: false,
                isBoundTransaction: false,
                isDropdownCancellation: false
            };
        }

        const { showRescindBtn, canQuote } = this.state;
        const effectDate = _.get(cancellationVM, 'effectiveDate');
        const toDate = new Date(effectDate.year, effectDate.month, effectDate.day);
        const isFuture = moment().isBefore(moment(toDate));
        if (this.referredUWACancellation()) {
            return {};
        }
        return {
            isContinueTransaction: cancellationVM.canBind && cancellationVM.status === 'Draft' && canQuote,
            isWithdrawTransaction: cancellationVM.canWithdraw,
            isSubmitTransaction: cancellationVM.status === 'Quoted' && cancellationVM.cancellationWithLoss_Ext === 'No' && !isFuture && cancellationVM.underwritingResponse_Ext !== 'decline',
            isEditTransaction: cancellationVM.status === 'Quoted' && cancellationVM.cancelReasonCode !== 'nottaken',
            isRescindTransaction: cancellationVM.status === 'Canceling' && showRescindBtn,
            isBoundTransaction: cancellationVM.status === 'Bound',
            isDropdownCancellation: cancellationVM.status === 'Quoted' && isFuture
        };
    }

    getPolicyLinkVisible = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: {
                    loadCancellationSummary: cancellationVM
                }
            }
        } = this.props;

        return (!_.isEmpty(cancellationVM.latestPeriod) && (cancellationVM.latestPeriod.policyNumber) && cancellationVM.latestPeriod.policyNumber !== 'Unassigned');
    };

    getMinData = (cancellationDate) => {
        const formatedDate = new Date(cancellationDate);
        formatedDate.setDate(formatedDate.getDate() - 1);
        return formatedDate;
    };

    getMaxData = (cancellationDate) => {
        const plusOneDay = moment(cancellationDate).add(1, 'day');
        return {
            year: plusOneDay.year(),
            month:plusOneDay.month(),
            day: plusOneDay.daysInMonth()
        };
    };

    getRefundInfo = (cancellation) => {
        const translator = this.context;
        const valueInCost = cancellation.changeInCost;
        if (cancellation.isRefund && valueInCost.amount < 0) {
            valueInCost.amount *= -1;
        }
        return {
            label: translator(messages.refundAmount),
            value: valueInCost
        };
    };

    genCancelDTO = () => {
        const { selectedReasonCode } = this.state;
        return {
            cancellationSource: 'insured',
            isTempCancellation: true,
            allCancelReasonCode_Ext: selectedReasonCode,
            // cancellationRefundMethod: selectedRefundMethod,
        };
    };


    getAdditionalInsured = (cancellation) => {
        if (cancellation.productCode === 'PersonalAuto') {
            const drivers = _.get(cancellation, 'policy.lobs.personalAuto.driverDTOs');
            if (!_.isNil(drivers)){
                const filteredList = drivers.filter(
                    (item) => (item.additionalInsured_Ext === true || item.relationshipToInsured_Ext === 'Insured_Ext') && item.driverCovered_Ext === true
                );
                return filteredList;
            }
        }
        if (cancellation.productCode === 'Watercraft') {
            const drivers = _.get(cancellation, 'policy.lobs.watercraft.waloperators');
            if (!_.isNil(drivers)){
                const filteredList = drivers.filter(
                    (item) => item.additionalInsured === true || item.relationship === 'Insured_Ext');
                return filteredList;
            }
        }
        if (cancellation.productCode === 'RoadTrail') {
            const drivers = _.get(cancellation, 'policy.lobs.roadTrail.rtoperators');
            if (!_.isNil(drivers)){
                const filteredList = drivers.filter(
                    (item) => item.additionalInsured === true || item.relationship === 'Insured_Ext');
                return filteredList;
            }
        }
        if (cancellation.productCode === 'HOPHomeowners') {
            const firstName = _.get(cancellation, 'policy.lobs.homeOwners.dwellingDTO.personalDetails.firstName');
            const lastName = _.get(cancellation, 'policy.lobs.homeOwners.dwellingDTO.personalDetails.lastName');
            return [{
                    displayName: `${firstName} ${lastName}`
                }]
        }
        const isCL = WniProductsUtil.isCLProduct(cancellation.productCode);
        if (isCL) {
            const contactName = _.get(cancellation, 'policy.account.accountHolder.contactName');
            return [{
                    displayName: contactName
                }]
        }
        return [];
    };

    writeValue = (value, path) => {
        const {
            fromAccountLanding: {
                quoteDetailsData: {
                    loadCancellationSummary: cancellationVM
                }
            }
        } = this.props;

        if (path === 'cancelReasonCode') {
            this.setState({ selectedReasonCode: value, cancelDescription: '' });
        }
        if (path === 'description') {
            this.setState({ cancelDescription: value });
        }
        if (path === 'effectiveDate' && value) {
            _.set(cancellationVM, path, value);
            this.validateEffectiveDate(value);
        }
    };

    renderRefundLabel = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: {
                    loadCancellationSummary: cancellationVM
                }
            }
        } = this.props;
        const productCode = _.get(cancellationVM, 'productCode');
        const translator = this.context;
        if (WniProductsUtil.isCLProduct(productCode)) {
            return translator(messagesExt.CLRefundLabel)
        }
        return translator(messagesExt.PLRefundLabel)
    }

    render() {
        const translator = this.context;
        const {
            isLoading, reasonCodeList, selectedReasonCode, isReadOnly,
            retainInAgency, validationIssuesWarning, canEffectiveDateQuoteCancel, showEffectiveDateValidation
        } = this.state;
        const documentEmpty = (retainInAgency && _.get(retainInAgency, 'length') > 0);
        const {
            interactionModel,
            fromAccountLanding: {
                quoteDetailsData: {
                    loadCancellationSummary: cancellationVM
                }
            },
            history
        } = this.props;
        const {
            policy: {
                account: {
                    accountNumber,
                    accountHolder: {
                        contactType_Ext: accountType
                    } = {}
                },
                policyNumber,
            },
        } = cancellationVM;

        const overrides = {
            backToPolicyCetailsLink: {
                // eslint-disable-next-line react/prop-types
                ...interactionModel.getURLObj(null, 'policySummary', accountNumber, policyNumber)
            },
            quoteNotification: {
                notificationContent: this.getNotificationContent(),
                transactionVisibleActions: this.getCancellationToProceed(),
                isEditing: isLoading,
                isQuoting: isLoading,
                isDeleting: isLoading,
                isSubmitting: isLoading,
                isRescinding: isLoading,
                visible: !this.referredUWACancellation()
            },
            underwritingComponentContainer: {
                visible: this.referredUWACancellation()
            },
            policyInfoLink: {
                visible: this.getPolicyLinkVisible()
            },
            detailGridTotalColumn: {
                visible: cancellationVM.statusCode === 'Quoted'
            },
            cancellationCreatedDate: {
                value: _.get(cancellationVM, 'createTime')
            },
            effectiveDateId: {
                value: _.get(cancellationVM, 'effectiveDate'),
                onValueChange: this.writeValue,
                minDate: cancellationVM.cancelReasonCode !== 'nottaken' ? WniDateUtil.getDateObj(cancellationVM.earliestCancellationDate) : null,
                maxDate: cancellationVM.cancelReasonCode !== 'nottaken' ? this.getMaxData(cancellationVM.actualExpirationDate) : null,
                disabled: isReadOnly,
                showErrors: true,
                validationMessages: (!canEffectiveDateQuoteCancel && showEffectiveDateValidation) ? [translator(messagesExt.validateEffectiveDateMsg)] : null,
                labelPositionForDate: 'top'
            },
            reasonSelectId: {
                availableValues: reasonCodeList,
                value: cancellationVM.cancelReasonCode === 'outofbusiness' ? 'Out of business/sold' : translator({
                    id: `typekey.ReasonCode.${cancellationVM.cancelReasonCode}`,
                    defaultMessage: cancellationVM.cancelReasonCode
                }),
                readOnly: true
            },
            cancellationDescriptionId: {
                readOnly: isReadOnly,
                visible: selectedReasonCode === 'other' || false
            },
            refundMethodId: {
                value: translator({
                    id: `typekey.CalculationMethod.${cancellationVM.cancellationRefundMethod}`,
                    defaultMessage: cancellationVM.cancellationRefundMethod
                }),
                label: this.renderRefundLabel()
            },
            dynamicInlineNotificationContainer: {
                validationIssues: validationIssuesWarning,
                visible: validationIssuesWarning.length > 0,
                scrollToIssues: true,
            },
            refundAmountDetails: this.getRefundInfo(cancellationVM),
            documentcheckedList: {
                visible: documentEmpty,
                history: history,
                submission: cancellationVM,
                renderData: [
                    {
                        data: retainInAgency
                    },
                    {
                        data: []
                    }
                ],
                showSendViaEmail: true,
                useJobNumber: true
            },
            additionalInsuredNameList: {
                data: this.getAdditionalInsured(cancellationVM)
            },
            quoteCancellationLoader: {
                loaded: !isLoading
            },
            backLinkContainer: {
                accountNumber: accountNumber,
                accountType: accountType,
                history: history,
            }
        };
        const resolvers = {
            resolveClassNameMap: styles,
            resolveComponentMap: {
                notificationcomponent: SuccessNotification,
                documentcheckedcomponent: DocumentCheckedComponent,
                validationissuescomponent: ValidationIssuesComponent,
                backlinkcomponent: BackLinkComponent,
                loader: Loader
            },
            resolveCallbackMap: {
                onSubmitTransaction: this.onCancelNow,
                onEditTransaction: this.onEditCancellation,
                onRescindTransaction: this.onRescindCancellation,
                onContinueTransaction: this.onQuoteCancellation,
                onWithdrawTransaction: this.onWithdrawCancellation,
                validateEffectiveDate: this.validateEffectiveDate,
                onScheduleCancel: this.onScheduleCancel,
                onCancelNow: this.onCancelNow
            }
        };

        const readValue = (id, path) => {
            return readViewModelValue(metadata.pageContent, cancellationVM, id, path, overrides);
        };

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={cancellationVM}
                overrideProps={overrides}
                resolveValue={readValue}
                onValueChange={this.writeValue}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        );
    }
}

const CancellationSummary = withModalContext(CancellationSummaryWithoutModalContext);

export default withViewModelService(withAuthenticationContext(withDependencies(['interactionModel'])(CancellationSummary)));
