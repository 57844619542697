const yearReg = /^(19[0-9]{2}|2[0-9]{3})$/

const inputSetModelMaps = {
    ca7_vin_ext: {
        datatype: 'string',
        component: 'WniInputText',
        value: 'fieldValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {}
    },
    ca7_year_ext: {
        datatype: 'number',
        component: 'WniInputNumber',
        value: 'integerValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => Number(val),
        defaultFieldProps: {
            showGrouping: false,
            decimalPlaces: 0,
            minValue: 1900,
            maxValue: 2999,
            nullable: true,
            validator: {
                pattern: yearReg,
                message: 'Value must be a valid year between 1900 and 2999'
            }
        }
    },
    year_ext: {
        datatype: 'number',
        component: 'WniInputNumber',
        value: 'integerValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => Number(val),
        defaultFieldProps: {
            showGrouping: false,
            decimalPlaces: 0,
            minValue: 1900,
            maxValue: 2999,
            nullable: true,
            validator: {
                pattern: yearReg,
                message: 'Value must be a valid year between 1900 and 2999'
            }
        }
    },
    ca7_classcode_ext: {
        datatype: 'string',
        component: 'WniInputTextWithAction',
        value: 'fieldValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {}
    },
    date: {
        datatype: 'date',
        component: 'WniDate',
        value: 'dateValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {
            dataType: 'object'
        }
    },
    dynamicdate: {
        datatype: 'date',
        component: 'WniDate',
        value: 'dateValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {}
    },
    booleanradio: {
        datatype: 'boolen',
        component: 'WniToggle',
        value: 'boolenValue',
        triggerFunc: 'onValueChange',
        formatDefaultValue: (val) => val === 'true',
        defaultFieldProps: {}
    },
    dynamicbooleanradio: {
        datatype: 'boolen',
        component: 'WniToggle',
        value: 'boolenValue',
        triggerFunc: 'onValueChange',
        formatDefaultValue: (val) => val === 'true',
        defaultFieldProps: {}
    },
    Boolean: {
        datatype: 'boolen',
        component: 'WniToggle',
        value: 'boolenValue',
        triggerFunc: 'onValueChange',
        formatDefaultValue: (val) => val === 'true',
        defaultFieldProps: {}
    },
    integer: {
        datatype: 'number',
        component: 'WniInputNumber',
        value: 'integerValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => Number(val),
        defaultFieldProps: {
            decimalPlaces: 0
        }
    },
    Integer: {
        datatype: 'number',
        component: 'WniInputNumber',
        value: 'integerValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => Number(val),
        defaultFieldProps: {
            decimalPlaces: 0
        }
    },
    AutoNumber: {
        datatype: 'number',
        component: 'WniInputNumber',
        value: 'rangeValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => Number(val),
        defaultFieldProps: {
            decimalPlaces: 0
        }
    },
    Percent: {
        datatype: 'number',
        component: 'WniInputNumber',
        value: 'integerValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => Number(val),
        defaultFieldProps: {
            decimalPlaces: 0
        }
    },
    IntegerRange: {
        datatype: 'number',
        component: 'WniInputNumber',
        value: 'integerValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => Number(val),
        defaultFieldProps: {
            decimalPlaces: 0,
            maxValue: 99999999,
            minValue: 0
        }
    },
    dynamicinteger: {
        datatype: 'number',
        component: 'WniInputNumber',
        value: 'integerValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => Number(val),
        defaultFieldProps: {
            decimalPlaces: 0
        }
    },
    BigDecimal: {
        datatype: 'number',
        component: 'WniInputNumber',
        value: 'decimalValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => Number(val),
        defaultFieldProps: {
            decimalPlaces: 0
        }
    },
    range: {
        datatype: 'string',
        component: 'WniDropdownSelect',
        value: 'rangeValue',
        triggerFunc: 'onValueChange',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {
            singleSelect: true,
            searchable: true
        }
    },
    dynamicrange: {
        datatype: 'string',
        component: 'WniDropdownSelect',
        value: 'rangeValue',
        triggerFunc: 'onValueChange',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {
            singleSelect: true,
            dataType: "string",
            searchable: true
        }
    },
    TypeKey: {
        datatype: 'string',
        component: 'WniDropdownSelect',
        value: 'rangeValue',
        triggerFunc: 'onValueChange',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {
            singleSelect: true,
            searchable: true
        }
    },
    ForeignKey: {
        datatype: 'string',
        component: 'WniDropdownSelect',
        value: 'fieldValue',
        triggerFunc: 'onValueChange',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {
            singleSelect: true,
            searchable: true
        }
    },
    ForeignKeyWithOptionLabels: {
        datatype: 'string',
        component: 'WniDropdownSelect',
        value: 'fieldValue',
        triggerFunc: 'onValueChange',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {
            singleSelect: true,
            searchable: true
        }
    },
    Option: {
        datatype: 'string',
        component: 'WniDropdownSelect',
        value: 'rangeValue',
        triggerFunc: 'onValueChange',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {
            singleSelect: true,
            searchable: true
        }
    },
    picker: {
        datatype: 'date',
        component: 'WniDate',
        value: 'fieldValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {}
    },
    dynamicpicker: {
        datatype: 'date',
        component: 'WniDate',
        value: 'fieldValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {}
    },
    Date: {
        datatype: 'date',
        component: 'WniDate',
        value: 'dateValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {}
    },
    rangeradio: {
        datatype: 'boolen',
        component: 'WniToggle',
        value: 'boolenValue',
        triggerFunc: 'onValueChange',
        formatDefaultValue: (val) => val === 'true',
        defaultFieldProps: {}
    },
    dynamicrangeradio: {
        datatype: 'boolen',
        component: 'WniToggle',
        value: 'boolenValue',
        triggerFunc: 'onValueChange',
        formatDefaultValue: (val) => val === 'true',
        defaultFieldProps: {}
    },
    searchablerange: {
        datatype: 'string',
        component: 'WniDropdownWithAction',
        value: 'rangeValue',
        triggerFunc: 'onValueChange',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {
            searchable: true
        }
    },
    textarea: {
        datatype: 'string',
        component: 'WniTextArea',
        value: 'fieldValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {}
    },
    TextArea: {
        datatype: 'string',
        component: 'WniTextArea',
        value: 'fieldValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {}
    },
    dynamictextarea: {
        datatype: 'string',
        component: 'WniTextArea',
        value: 'fieldValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {}
    },
    decimal: {
        datatype: 'number',
        component: 'WniInputNumber',
        value: 'decimalValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => Number(val),
        defaultFieldProps: {}
    },
    dynamicdecimal: {
        datatype: 'number',
        component: 'WniInputNumber',
        value: 'decimalValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => Number(val),
        defaultFieldProps: {}
    },
    default: {
        datatype: 'string',
        component: 'WniInputText',
        value: 'fieldValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {}
    },
    dynamicdefault: {
        datatype: 'string',
        component: 'WniInputText',
        value: 'fieldValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {}
    },
    String: {
        datatype: 'string',
        component: 'WniInputText',
        value: 'fieldValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {}
    },
    label: {
        component: 'div'
    },
    gl7_exposure_decimal_ext: {
        datatype: 'number',
        component: 'WniInputNumber',
        value: 'decimalValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => Number(val),
        defaultFieldProps: {}
    },
    gl7_exposure_longint_ext: {
        datatype: 'number',
        component: 'WniInputNumber',
        value: 'bigIntegerValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => Number(val),
        defaultFieldProps: {
            decimalPlaces: 0
        }
    },
    cp7_numstories_ext: {
        datatype: 'number',
        component: 'WniInputNumber',
        value: 'fieldValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => Number(val),
        defaultFieldProps: {
            showGrouping: false,
            decimalPlaces: 0,
            minValue: 0
        }
    },
    cp7_square_ext: {
        datatype: 'number',
        component: 'WniInputNumber',
        value: 'fieldValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => Number(val),
        defaultFieldProps: {
            showGrouping: false,
            decimalPlaces: 0,
            minValue: 0,
        }
    }
};

const getFieldModel = (inputSetModel) => {
    return inputSetModelMaps[inputSetModel] || {}
}

const getDefaultValue = (val, fieldModel) => {
    if(!val) {
        return null
    }
    return fieldModel.formatDefaultValue(val)
};

const isYearValid = (year, fieldItem = {}) => {
    if(!fieldItem.required && !year){
        return true
    }
    return yearReg.test(year)
}

const isNumberValid = (value, fieldModel = {}) => {
    if(!fieldModel.minValue && !fieldModel.maxValue) {
        return true;
    }

    if(fieldModel.minValue && value < fieldModel.minValue) {
        return false;
    }
    if(fieldModel.maxValue && value > fieldModel.maxValue) {
        return false;
    }
    return true;
};

export {
    inputSetModelMaps,
    getFieldModel,
    getDefaultValue,
    isYearValid,
    isNumberValid
}