import { setComponentMapOverrides } from '@jutro/uiconfig';

import IMDummyPage from './pages/Dummy/IMDummyPage';
import IMDummyReadOnlyPage from './pages/Dummy/IMDummyPage.readonly';

import IMAccountsReceivablePage from './pages/IMAccountsReceivable/IMAccountsReceivablePage';
import IMAccountsReceivablerReadOnlyPage from './pages/IMAccountsReceivable/IMAccountsReceivablePage.readonly'

import IMUnderwritingQuestionsPage from './pages/UnderwritingQuestions/IMUnderwritingQuestionsPage';
import IMUnderwritingQuestionsReadOnlyPage from './pages/UnderwritingQuestions/IMUnderwritingQuestionsPage.readonly';

import IMQuotePage  from './pages/Quote/IMQuotePage';
import IMQuoteReadOnlyPage from './pages/Quote/IMQuotePage.readonly';

import IMModifiersPage from './pages/Modifiers/IMModifiersPage';
import IMModifiersReadOnlyPage from './pages/Modifiers/IMModifiersPage.readonly';

import IMBuildingsAndLocationsPage from './pages/BuildingsAndLocations/IMBuildingsAndLocationsPage';
import IMBuildingsAndLocationsReadOnlyPage  from './pages/BuildingsAndLocations/IMBuildingsAndLocationsPage.readonly';

import IMCoveragePartSelectionPage from './pages/CoveragePartSelection/IMCoveragePartSelectionPage';
import IMCoveragePartSelectionReadOnlyPage from './pages/CoveragePartSelection/IMCoveragePartSelectionPage.readonly';

setComponentMapOverrides(
    {
        IMDummyPage: {component: 'IMDummyPage'},
        IMDummyReadOnlyPage: { component: 'IMDummyReadOnlyPage' },

        IMAccountsReceivablePage: {component: 'IMAccountsReceivablePage'},
        IMAccountsReceivablerReadOnlyPage: {component: 'IMAccountsReceivablerReadOnlyPage'},

        IMUnderwritingQuestionsPage: {component: 'IMUnderwritingQuestionsPage'},
        IMUnderwritingQuestionsReadOnlyPage: { component: 'IMUnderwritingQuestionsReadOnlyPage' },

        //
        IMQuotePage: {component: 'IMQuotePage'},
        IMQuoteReadOnlyPage: {component: 'IMQuoteReadOnlyPage'},

        IMModifiersPage: {component: 'IMModifiersPage'},
        IMModifiersReadOnlyPage: {component: 'IMModifiersReadOnlyPage'},

        IMBuildingsAndLocationsPage: {component: 'IMBuildingsAndLocationsPage'},
        IMBuildingsAndLocationsReadOnlyPage: {component: 'IMBuildingsAndLocationsReadOnlyPage'},

        IMCoveragePartSelectionPage: {component: 'IMCoveragePartSelectionPage'},
        IMCoveragePartSelectionReadOnlyPage: {component: 'IMCoveragePartSelectionReadOnlyPage'}
    },
    {
        IMDummyPage,
        IMDummyReadOnlyPage,

        IMAccountsReceivablePage,
        IMAccountsReceivablerReadOnlyPage,

        IMUnderwritingQuestionsPage,
        IMUnderwritingQuestionsReadOnlyPage,

        //
        IMQuotePage,
        IMQuoteReadOnlyPage,

        IMModifiersPage,
        IMModifiersReadOnlyPage,

        IMBuildingsAndLocationsPage,
        IMBuildingsAndLocationsReadOnlyPage,

        IMCoveragePartSelectionPage,
        IMCoveragePartSelectionReadOnlyPage
    }
);


export {
    IMDummyPage,
    IMDummyReadOnlyPage,
    IMAccountsReceivablePage,
    IMAccountsReceivablerReadOnlyPage,
    IMModifiersPage,
    IMModifiersReadOnlyPage,
    IMBuildingsAndLocationsPage,
    IMBuildingsAndLocationsReadOnlyPage,
    IMUnderwritingQuestionsPage,
    IMUnderwritingQuestionsReadOnlyPage,
    IMCoveragePartSelectionPage,
    IMCoveragePartSelectionReadOnlyPage
    //
};

 
export { default as IMSubmissionWizard } from './IMSubmissionWizard';
export { default as IMWizardPage } from './templates/IMWizardPage';

export { default as IMQuoteIssueRenderHelper } from './pages/Quote/util/IMQuoteIssueRenderHelper';
export { default as IMQuotePageConstants } from './pages/Quote/config/IMQuotePage.config';
