import React, {
    useEffect,
    useContext,
} from 'react'
import {
    ValueType,
} from '../../util/IMScheduleItemsUtil'
import ScheduleItemEditableCellDropdown from './IMScheduleItemEditableCellComponents/ScheduleItemEditableCellDropdown';
import ScheduleItemEditableCellText from './IMScheduleItemEditableCellComponents/ScheduleItemEditableCellText';
import ScheduleItemEditableCellBigDecimal from './IMScheduleItemEditableCellComponents/ScheduleItemEditableCellBigDecimal';
import ScheduleItemEditableCellCurrency from './IMScheduleItemEditableCellComponents/ScheduleItemEditableCellCurrency';
import ScheduleItemEditableCellDate from './IMScheduleItemEditableCellComponents/ScheduleItemEditableCellDate';
import ScheduleItemEditableCellBoolean from './IMScheduleItemEditableCellComponents/ScheduleItemEditableCellBoolean';
import ScheduleItemEditableCellLocalDateObj from './IMScheduleItemEditableCellComponents/ScheduleItemEditableCellLocalDateObj';
import ScheduleItemEditableCellInteger from './IMScheduleItemEditableCellComponents/ScheduleItemEditableCellInteger';
import SingleClauseContext from '../../context/IMSingleClauseContext';
import IMCoverageUtil from '../../util/IMCoverageUtil';

const IMScheduleItemEditableCell = (props) => {
    const {
        propertyInfo,
        scheduleItem,
        scheduleItemVMPath,
        scheduleItemIndex,
    } = props

    const {
        id,
        valueTypeName_Ext: valueTypeName,
        isCovTermPropertyInfo_Ext: isCovTermPropertyInfo,
        schedulePropertyInfoType_Ext: schedulePropertyInfoType,
    } = propertyInfo;

    const {
        itemData: {
            [id]: scheduleItemData
        }
    } = scheduleItem

    const valueType = isCovTermPropertyInfo ? valueTypeName : schedulePropertyInfoType

    const {
        onValidate,
        clauseCode,
    } = useContext(SingleClauseContext)

    useEffect(() => {
        const scheduleItemValidationID = `${clauseCode}_scheduleItem_${scheduleItemIndex}_${id}`
        if (onValidate) {
            onValidate(!IMCoverageUtil.isScheduledItemDataInvalid(scheduleItemData, propertyInfo), scheduleItemValidationID);
        }
        return () => {
            if (onValidate) {
                onValidate(true, scheduleItemValidationID);
            }
        }
    }, [clauseCode, id, onValidate, propertyInfo, scheduleItemData, scheduleItemIndex])

    const commonEditableCellComponentProps = {
        propertyInfo,
        scheduleItem,
        scheduleItemVMPath,
    }

    switch (valueType) {
        case ValueType.Option:
        case ValueType.AdditionalInsured:
        case ValueType.AdditionalInterest:
        case ValueType.ForeignKey:
        case ValueType.ForeignKeyWithOptionLabels:
        case ValueType.PolicyContact:
        case ValueType.TypeKey:
            return <ScheduleItemEditableCellDropdown
                {...commonEditableCellComponentProps}
            />
        case ValueType.Shorttext:
        case ValueType.String:
        case ValueType.TextArea:
            return <ScheduleItemEditableCellText
                {...commonEditableCellComponentProps}
            />
        case ValueType.Direct:
        case ValueType.BigDecimal:
            return <ScheduleItemEditableCellBigDecimal
                {...commonEditableCellComponentProps}
            />
        case ValueType.Integer:
        case ValueType.IntegerRange:
        case ValueType.AutoNumber:
            return <ScheduleItemEditableCellInteger
            {...commonEditableCellComponentProps}
            />
        case ValueType.MONEY_EXT:
            return <ScheduleItemEditableCellCurrency
                {...commonEditableCellComponentProps}
            />
        case ValueType.DateTime:
            return <ScheduleItemEditableCellDate
                {...commonEditableCellComponentProps}
            />
        case ValueType.Date:
            return <ScheduleItemEditableCellLocalDateObj
                {...commonEditableCellComponentProps}
            />
        case ValueType.Bit:
        case ValueType.Boolean:
            return <ScheduleItemEditableCellBoolean
                {...commonEditableCellComponentProps}
            />
        default:
            return <div>Unsupport type editable cell</div>
    }

}

export default IMScheduleItemEditableCell
