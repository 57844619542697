import { defineMessages } from 'react-intl';

export default defineMessages({
    MortgageeDetails: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.MortgageeDetails.Mortgagee Details',
        defaultMessage: 'Mortgagee Details',
    },
    Delete: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.MortgageeDetails.Delete',
        defaultMessage: 'Delete',
    },
    Add: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.MortgageeDetails.Add',
        defaultMessage: 'Add',
    },
    Name: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.MortgageeDetails.Name',
        defaultMessage: 'Name',
    },
    InterestType: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.MortgageeDetails.Interest Type',
        defaultMessage: 'Interest Type',
    },
    SendBill: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.MortgageeDetails.Send Bill',
        defaultMessage: 'Send Bill',
    },
    LoanNumber: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskClauses.MortgageeDetails.Loan Number',
        defaultMessage: 'Loan Number',
    }
});