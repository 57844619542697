import { Button,ModalBody,ModalFooter,ModalHeader,ModalNext } from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import React,{ useState } from 'react';
import _ from 'lodash';
import { GLLocationService } from 'wni-capability-quoteandbind-gl';
import metadata from './AssociatedExposuresPopup.metadata.json5';
import GLLocationsUtil from '../../Util/GLLocationsUtil';

function AssociatedExposuresPopup(props) {
    const {
        isOpen,
        size,
        title,
        onReject,
        onResolve,
        removeSelection,
        transferLocations = [],
        exposures = [],
        useDependenciesData,
        externalData: {
            jobID,
            sessionUUID,
            authHeader
        },
        locationService = GLLocationService
    } = props;

    const { loadingMask: { setLoadingMask } } = useDependenciesData;

    const [isTransfer, updateIsTransfer] = useState(false);
    const [transferLocationIds, updateTransferLocationIds] = useState([]);

    const locationData = transferLocations.map((item) => {
        return {
            ...item,
            isPrimary: _.get(item, `isPrimary`) ? 'X' : '-',
            addressDisplayName: GLLocationsUtil.getPrimaryAddressDisplayName(item.address || item.address_Ext)
        }
    });

    const onRemove = async() => {
        setLoadingMask(true);
        const res = await locationService.removeLocationsAndExposures(jobID, sessionUUID, removeSelection, authHeader);
        setLoadingMask(false);
        onResolve(res);
    };

    const onTransfer = () => {
        updateIsTransfer(true);
        
    };

    const onTransferLocation = async() => {
        setLoadingMask(true);
        const res = await locationService.transferExposureLocation(jobID, sessionUUID, removeSelection, transferLocationIds[0], authHeader);
        setLoadingMask(false);
        onResolve(res);
    };

    const overrideProps = {
        exposureTable: {
            data: exposures
        },
        locationTable: {
            data: locationData,
            onSelectionChange: (rows) => updateTransferLocationIds(rows)
        },
        associatedExposureSection: {
            visible: !isTransfer
        },
        locationSection: {
            visible: isTransfer
        }
    };

    const resolvers = {
        
    };
    return (
        <ModalNext isOpen={isOpen} className={`${size}`}>
            <Button className="modal-close" icon="gw-close" onClick={onReject} />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    model={{}}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                />
            </ModalBody>
            {!isTransfer ? (
                <ModalFooter>
                    <Button onClick={onRemove}>Delete</Button>
                    <Button onClick={onTransfer} type="outlined">Transfer</Button>
                </ModalFooter>
            ) : (
                <ModalFooter>
                    <Button onClick={onReject} type="outlined">Cancel</Button>
                    <Button onClick={onTransferLocation} disabled={_.isEmpty(transferLocationIds)}>Transfer</Button>
                </ModalFooter>
            )}
        </ModalNext>
    );
}

export default AssociatedExposuresPopup;
