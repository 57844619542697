import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { QuestionSetComponent } from 'gw-components-platform-react';
import { GLUnderwritingQuestionsService } from 'wni-capability-quoteandbind-gl';
import {
    QuoteUtil
} from 'wni-portals-util-js';
import WizardPage from '../../templates/GLWizardPage';

import metadata from './GLUnderwritingQuestionsPage.metadata.json5';

function GLUnderwritingQuestionsPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        glUnderwritingQuestionsService,
        //
        // onPreQuotePageNext,
    } = props;

    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const viewModelService = useContext(ViewModelServiceContext);
    const {
        initialValidation,
        onValidate,
        isComponentValid
    } = useValidation('GLUnderwritingQuestionsPage');

    const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);
    const [showErrors, updateShowErrors] = useState(false);

    const {
        jobID,
        sessionUUID
    } = submissionVM.value;

    useEffect(() => {
        setLoadingMask(isServiceCallInProgress);
    }, [isServiceCallInProgress, setLoadingMask]);

    const handleValidation = () => {
        updateShowErrors(true);
        return false;
    };

    const getQuestionMapper = useCallback(() => {
        return (metaDataContent) => {
            if (metaDataContent.id === 'GL7ForeignProducts') {
                _.set(metaDataContent, 'component', 'WniToggle')
                _.set(metaDataContent,'componentProps.tooltip', true);
                _.set(metaDataContent, 'componentProps.tooltip.showOnInit', false)
                _.set(metaDataContent, 'componentProps.tooltip.text', 'ACORD 815 - International Liability Exposure Supplement')
            }
            return metaDataContent;
        }
    }, []);

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                labelPosition: 'left',
            },
            questionSetsContainer: {
                contentMapper: getQuestionMapper()
            }
        };
    }, []);

    const onPageNext = useCallback(async () => {
        
        const {
            onPreQuotePageNext, // = onDefaultPageNext,
        } = props;
        
        updateServiceCallInProgress(true);
        const requestData = {
            jobID,
            sessionUUID,
            dto: _.get(submissionVM, 'lobData.generalLiability.underwritingQuestionSets.value')
        };
        const res = await glUnderwritingQuestionsService.updateUnderwritingQuestions(requestData, authHeader);
        const newSubmissionVM = viewModelService.clone(submissionVM);
        if (!_.isEmpty(res)) {
            _.set(newSubmissionVM, 'lobData.generalLiability.underwritingQuestionSets.value', res.underwritingQuestionSets);
        }
        updateServiceCallInProgress(false);
        updateWizardData(newSubmissionVM);

        //
        let retval = newSubmissionVM;
        if (_.isFunction(onPreQuotePageNext)) {
            retval = await onPreQuotePageNext();
        }
        return retval;
    }, [glUnderwritingQuestionsService, submissionVM, updateWizardData]);

    const writeValue = useCallback((value, path) => {
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM, path, value);
        updateWizardData(newSubmissionVM);
    }, [submissionVM]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            questionset: QuestionSetComponent
        }
    };

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={isComponentValid ? onPageNext : handleValidation}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValueChange={writeValue}
                showErrors={showErrors}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

GLUnderwritingQuestionsPage.propTypes = WizardPage.propTypes;
GLUnderwritingQuestionsPage.defaultProps = WizardPage.defaultProps;

GLUnderwritingQuestionsPage.propTypes = {
    ...wizardProps,
    glUnderwritingQuestionsService: PropTypes.object
};

GLUnderwritingQuestionsPage.defaultProps = {
    glUnderwritingQuestionsService: GLUnderwritingQuestionsService
};
export default GLUnderwritingQuestionsPage;