import { defineMessages } from 'react-intl';

export default defineMessages({
    
    removeTitle: {
        id: 'quoteandbind.gl.directives.templates.gl-edit-unmanned.deleteTitle',
        defaultMessage: 'Want to Delete?',
    },
    removeDescription: {
        id: 'quoteandbind.gl.directives.templates.gl-edit-unmanned.deleteMessage',
        defaultMessage: 'Are you sure you want to delete this record?',
    },
});
