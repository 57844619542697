import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWniModal } from 'wni-components-platform-react';
import {
    // ErrorsAndWarningsUtil,
    // WizardUtil,
    // WindowUtil,
    QuoteUtil,
} from 'wni-portals-util-js';
// import { ErrorsAndWarningsDisplayComponent, ValidationIssuesComponent } from 'wni-components-platform-react';
// import { PortalConstants, WizardConstants } from 'wni-portals-config-js';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import WizardPage from '../../templates/IMWizardPage';
import metadata from './IMBuildingsAndLocationsPage.metadata.json5';
import messages from './IMBuildingsAndLocationsPage.messages';
import IMLocationDetailModal from './Components/IMLocationDetailModal/IMLocationDetailModal';
import IMBuildingDetailModal from './Components/IMBuildingDetailModal/IMBuildingDetailModal';

const COVERABLES_PATH = 'lobData.inlandMarine.coverables';
const unusedLocationsVMPath = `${COVERABLES_PATH}.unusedLocations`;
const unusedBuildingsVMPath = `${COVERABLES_PATH}.unusedBuildings`;
const locationsVMPath = `${COVERABLES_PATH}.locations`;

function IMBuildingsAndLocationsPage(props) {
    const modalApi = useWniModal();

    const {
        wizardData: submissionVM,
        updateWizardData,
        updateWizardSnapshot,
        updateWizardReadOnly,
        wizardSnapshot,
        isReadOnly,
        //
        wizardStepToFieldMapping,
        markFollowingWizardStepsUnvisited,
        jumpTo: wizardJumpTo,
        steps: wizardSteps,
        //
        wizardPageData,
        updateWizardPageData,
        resetWizardDataToSnapshot,
    } = props;

    const {
        jobID, sessionUUID,
        lobData: {
            inlandMarine: {
                coverables: {
                    unusedLocations = [],
                    unusedBuildings = [],
                    locations = []
                }
            }
        }
    } = submissionVM.value;

    const history = useHistory();
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    // const ViewModelService = useContext(ViewModelServiceContext);
    const { authHeader, authUserData: { isExternalUser_Ext: isExternalUser } } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const viewModelService = useContext(ViewModelServiceContext);
    const showQuoteStartDate = appConfig.showQuoteStartDateInHeader;
    const {
        initialValidation,
        onValidate,
        invalidFields,
        isComponentValid,
    } = useValidation('IMBuildingsAndLocationsPage');
    
    const [showErrors, updateShowErrors] = useState(false);

    const [validationIssues, updateValidationIssues] = useState(undefined);

    useEffect(() => {

    }, []);

    const handleValidation = useCallback(() => {
        //
    }, [invalidFields]);

    const writeValue = (value, path) => {
        // if(currentRow) {
        //     let newValue = _.clone(value);
        //     if(path === 'address_Ext') {
        //         const state = _.get(value, 'state');
        //         if(!_.includes(statesValues, state)) {
        //             newValue = _.set(value, 'state', '')
        //         }
        //     }
        //     _.set(currentRow.value, path, newValue);
        //     syncWizardData(currentRow);
        // }
    };

    const showLocationDetailModal = async () => {
        const componentProps = {
            title: translator(messages.locationDetailModalTitle, {operationType: 'New'}),
            actionBtnLabel: translator(commonMessages.ok),
            cancelBtnLabel: translator(commonMessages.cancelModel),
            extendProps: {
                jobID,
                sessionUUID,
                authHeader
            },
            onValueChange: writeValue
        }
        await modalApi.showModal(<IMLocationDetailModal {...componentProps} />).then(async(result) => {
            if(!result) {
                return false;
            }
            console.log('IMLocationDetailModal', result);
        }).catch(() => _.noop());
    }

    const showBuildingDetailModal = async () => {
        const componentProps = {
            title: translator(messages.locationDetailModalTitle, {operationType: 'New'}),
            actionBtnLabel: translator(commonMessages.ok),
            cancelBtnLabel: translator(commonMessages.cancelModel),
            extendProps: {
                jobID,
                sessionUUID,
                authHeader
            },
        }
        await modalApi.showModal(<IMBuildingDetailModal {...componentProps} />).then(async(result) => {
            if(!result) {
                return false;
            }
            console.log('IMBuildingDetailModal', result);
        }).catch(() => _.noop());
    }

    const onPageNext = useCallback(async () => {
        //
        return submissionVM;
    }, [authHeader, submissionVM]);

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
            },
            locationTable: {
                data: locations
            }
        };
    }, [breakpoint, submissionVM]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveCallbackMap: {
            showLocationDetailModal,
            showBuildingDetailModal
        },
        resolveComponentMap: {
        }
    };
    
    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.pageContent,
            submissionVM,
            id,
            path,
            overrideProps
        );
    };

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={isComponentValid ? onPageNext : handleValidation}
            pageLevelValidationIssues={validationIssues}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

IMBuildingsAndLocationsPage.propTypes = WizardPage.propTypes;
IMBuildingsAndLocationsPage.defaultProps = WizardPage.defaultProps;
export default IMBuildingsAndLocationsPage;