import { useTranslator, IntlContext } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import React, { useState, useContext, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { QuoteUtil, ValidationIssueUtil, WindowUtil } from 'wni-portals-util-js';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { GLExposureService } from 'wni-capability-quoteandbind-gl';
import { DropdownMenuLink, Icon } from '@jutro/components';
import { useWniModal } from 'wni-components-platform-react';
import { WizardErrorContext } from 'wni-portals-wizard-react';

import { PortalConstants } from 'wni-portals-config-js';

import { messages as commonMessages } from '@xengage/gw-platform-translations';
import WizardPage from '../../templates/GLWizardPage';
import ExposureComponent from './components/ExposureComponent';
import metadata from './GLExposuresPage.metadata.json5';
import messages from './GLExposuresPage.messages';

const PATH = 'lobData.generalLiability.coverables.exposures';
const CHILDREN_PATH = `${PATH}.children`;
const DTO_PATH = 'wni.edge.capabilities.quote.lob.generalliability.dto.GLExposureDTO';

const xCenter = 'pc';
const VALIDATION_ICON_MAP = {
    success: 'gw-check-circle',
    warning: 'gw-warning',
    error: 'gw-error',
};

function GLExposuresPage(props) {
    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData,
        updateWizardSnapshot,
        resetWizardDataToSnapshot,
        readOnly,
        currentStep,
        //
        markFollowingWizardStepsUnvisited,
        //
        checkRequiredForIssuance,
        isPolicyChange = false,
        //
        updateWizardPageData,
        // onPreQuotePageNext,
    } = props;

    const translator = useTranslator();
    const intlContext = useContext(IntlContext);
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');
    const { authHeader } = useAuthentication();

    const {
        lobData: {
            generalLiability: {
                coverables: {
                    sublineSelected,
                    exposures
                } = {}
            },
        },
        jobID,
        sessionUUID,
    } = submissionVM.value;
    const viewModelService = useContext(ViewModelServiceContext);
    const { updateWizardPageStickyIssues } = useContext(WizardErrorContext);

    const [showErrors, updateShowErrors] = useState(false);
    const [currentRow, updateCurrentRow] = useState(null);
    const [currentExposureClauses, setCurrentExposureClauses] = useState()
    const [isEditing, setIsEditing] = useState(false)
    const [selection, updateSelection] = useState([]);
    const [validationIssues, updateValidationIssues] = useState([]);
    const [displayWarnings, updateDisplayWarnings] = useState(false);
    const { initialValidation, onValidate, isComponentValid, invalidFields } = useValidation('GLExposuresPage');

    const highlightRowFn = (activeRow) => {
        const activePublicID = activeRow ? _.get(activeRow.value, 'rowIdPath') : null;
        WniTableRowUtil.setTablePublicIDSelected(activePublicID, 'exposureTable');
    };

    useEffect(() => {
        highlightRowFn(currentRow);
    }, [currentRow]);

    const updateSubmission = (currentVM) => {
        const rowIdPath = _.get(currentVM.value, 'rowIdPath');
        const allData = _.get(submissionVM.value, PATH);
        const currentIndex = allData.findIndex((item) => item.rowIdPath === rowIdPath); 
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM.value, `${PATH}[${currentIndex}]`, currentVM.value);
        
        _.set(newSubmissionVM.value, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        
        return newSubmissionVM;
    };

    const updateCurrentRowInteranl = (rowData, updateSubmissionData) => {
        if(!rowData) {
            updateCurrentRow(rowData);
            return false;
        }
        const initCurrentRow = viewModelService.create(rowData.value, xCenter, DTO_PATH);
        
        updateCurrentRow(initCurrentRow);
        if(updateSubmissionData && !readOnly) {
            const newSubmissionVM = updateSubmission(initCurrentRow);
            updateSubmissionData(newSubmissionVM)
        }
    };
  
    const syncWizardDataSnapshot = (currentVM) => {
        updateCurrentRowInteranl(currentVM, updateWizardSnapshot);
    };
    const syncWizardData = (currentVM) => {
        updateCurrentRowInteranl(currentVM, updateWizardData);
    };

    const handleValidation = useCallback(() => {
        updateShowErrors(true);
        WindowUtil.scrollToInvalidField(invalidFields); // scroll to the invalid fields
        return false;
    }, [invalidFields]);

    const setCurrentExposureClausesAndClearPages = useCallback((clausesParam) => {
        setCurrentExposureClauses(clausesParam);
        markFollowingWizardStepsUnvisited();
    }, [setCurrentExposureClauses]);

    const addExposure = async(item) => {
        // old data
        const allData = _.get(submissionVM, `${PATH}.value`, []);

        // add service
        setLoadingMask(true);
        const res = await GLExposureService.addExposure(jobID, sessionUUID, item.publicID, authHeader);
        setLoadingMask(false);
        if(!res.exposure) {
            const validationMessages = _.get(res, 'errorsAndWarnings.serverIssues_Ext.displayMssages', []);
            updateValidationIssues(validationMessages)
            return false;
        }
        // update exposure clauses
        const exposureClauses = _.get(res, 'exposureClauses')
        setCurrentExposureClausesAndClearPages(exposureClauses);

        // update submission
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM, `${PATH}.value`, [...allData, res.exposure]);
        updateWizardSnapshot(newSubmissionVM);
        updateShowErrors(false);

         // if find new vehicle, this is add new data
         const newItem= viewModelService.create(res.exposure, xCenter, DTO_PATH);
         updateCurrentRow(newItem);
    };

    const removeExposure = () => {
        modalApi.showConfirm({
            title: messages.removeTitle,
            message: messages.removeDescription,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok,
            cancelButtonText: commonMessages.cancelModel
        }).then(async(result) => {
            if (result === 'cancel' || result === 'close') {
                return false;
            }
            updateCurrentRow(null);
            
            setLoadingMask(true);
            const res = await GLExposureService.removeExposure(jobID, sessionUUID, selection, authHeader);
    
            // update submission
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM, `${PATH}.value`, res.exposures);
            updateWizardSnapshot(newSubmissionVM);
            updateShowErrors(false);
            setLoadingMask(false);
        })
    };

    const renderContent = () => {
        return sublineSelected.map((item) => {
            return <DropdownMenuLink key={item.publicID} onClick={() => addExposure(item)}>{item.subline}</DropdownMenuLink>
        });
    };

  
    const viewAndEdit = async(item) => {
        const currentPublicID = _.get(currentRow, 'value.publicID');
        if(currentPublicID === item.publicID) {
            return false;
        }
        setLoadingMask(true);
        const res = await GLExposureService.viewAndEdit(jobID, sessionUUID, item.publicID, authHeader);
        // update exposure clauses
        const exposureClauses = _.get(res, 'exposureClauses')
        setCurrentExposureClauses(exposureClauses)
        
        const initCurrentRow = viewModelService.create(res.exposure, xCenter, DTO_PATH);
        updateCurrentRow(initCurrentRow);
        WindowUtil.scrollTo('exposureDetailContainer')
        setLoadingMask(false);
    };

    const cancelExposure = () => {
        updateCurrentRow(null);
        resetWizardDataToSnapshot();
    };

    const generateValidationIssues = (issues) => {
        // const displayMssages = _.get(issues, 'serverIssues_Ext.displayMssages', []); 
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(issues);
        updateWizardPageStickyIssues(currentStep.id, []);
        updateValidationIssues(newValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }
        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            updateShowErrors(true);
            return false;
        }
        return true;
    };

    const updateExposure = async() => {
        if(!isComponentValid || !currentRow.aspects.valid || !currentRow.aspects.subtreeValid) {
            handleValidation();
            return false;
        };
        setLoadingMask(true);
        const res = await GLExposureService.saveExposure(jobID, sessionUUID, currentRow.value, authHeader);
        _.set(currentRow, 'value', res.exposure);
        syncWizardDataSnapshot(currentRow);
        setLoadingMask(false);
        const isPageValid = generateValidationIssues(res.errorsAndWarnings);
        if(!isPageValid) {
            return false;
        }
        return true;
    };

    const onNextExposure = () => {
        const childrenVM = _.get(submissionVM, CHILDREN_PATH);
        if(childrenVM.length < 2) {
            return true;
        }
        let index = _.findIndex(childrenVM, (vm) => vm.value.rowIdPath === currentRow.value.rowIdPath);
        if (index === childrenVM.length - 1) {
            index = 0;
        }else{
            index += 1;
        }
        const nextItem = _.get(childrenVM[index], 'value');
        syncWizardData(null);
        viewAndEdit(nextItem)
        WindowUtil.scrollToTop()
    };


    const findItemVMValid = (vm) => {
        const vmValid =  _.get(vm, 'aspects.valid') && _.get(vm, 'aspects.subtreeValid');
        const displayableValid = _.get(vm.value, 'displayableValid')
        return vmValid && displayableValid
    };

    const allDataValid = () => {
        const allDataVMs = _.get(submissionVM, CHILDREN_PATH);
        return allDataVMs.every((vm) => findItemVMValid(vm));
    };
    const renderValidationCell = (item, index) => {
        const childrenVM = _.get(submissionVM, CHILDREN_PATH);
        const itemVM = childrenVM.find((v) => v.value.rowIdPath === index) || {};
        let type;
        const isItemValid = findItemVMValid(itemVM);
        if(isItemValid) {
            type = 'success';
            // const issuacneInValidField = generateIssuanceValid(itemVM);
            // if(_.isEmpty(issuacneInValidField)) {
            //     type = 'success';
            // } else {
            //     type = 'warning';
            // }
        } else {
            type = 'error';
        }
        return <Icon id={`validationIcon${item.rowIdPath}`} icon={VALIDATION_ICON_MAP[type]} className={`wni-icon-${type}`} />
    };

    const onPageNext = async() => {
        if(!allDataValid()) {
            return false;
        }

        const {
            onPreQuotePageNext, // = onDefaultPageNext,
        } = props;

        const requestData = {
            jobID,
            sessionUUID
        }
        const res = await GLExposureService.onPageNext(requestData, authHeader);
        _.set(submissionVM.value, PATH, res.exposures);
        // Remove following line if it is not needed
        _.set(submissionVM.value, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        
        const isPageValid = generateValidationIssues(res.errorsAndWarnings);
        if(!isPageValid) {
            return false;
        }

        // return submissionVM;
        let retval = submissionVM;
        if (_.isFunction(onPreQuotePageNext)) {
            retval = await onPreQuotePageNext();
        }
        return retval;
    };

    const writeValue = (value, path) => {
        if(currentRow) {
            const isCurrencyField = _.isObject(value) && _.get(value, 'currency');
            if (isCurrencyField && (_.isNil(_.get(value, 'amount')) || _.get(value, 'amount') === '')){
                _.set(currentRow.value, path, undefined);
            } else {
                _.set(currentRow.value, path, value);
            }
            syncWizardData(currentRow);
        }
    };

    const overrideProps = {
        '@field': {
            readOnly
        },
        addVheicle: {
            visible: !readOnly && !isPolicyChange,
        },
        addExposureData: {
            data: sublineSelected,
            content: renderContent()
        },
        removeExposure:{
            visible: !readOnly && !isPolicyChange,
            disabled: _.isEmpty(selection)
        },
        exposureTable: {
            onSelectionChange: (rows) => updateSelection(rows),
            selectionType: readOnly ? 'none' : 'multi',
            selectedRows: selection,
        },
        viewAndEditLink: {
            label: readOnly ? messages.viewLabel : messages.viewAndEditLabel
        },
        exposureDetailContainer: {
            visible: !!currentRow && !!currentExposureClauses
        },
        exposureDetails: {
            currentRow,
            currentExposureClauses,
            setCurrentExposureClauses: setCurrentExposureClausesAndClearPages,
            onValueChange: writeValue,
            generateValidationIssues,
            wizardData: submissionVM,
            updateWizardData,
            syncWizardData,
            syncWizardDataSnapshot,
            onValidate,
            setIsEditing,
            isEditing,
            extendProps: {
                jobID,
                sessionUUID,
                authHeader
            }
        },
        saveNextButton: {
            visible: !readOnly && exposures.length > 1,
        },
        saveButton: {
            visible: !readOnly
        }
    };

    const resolvers = {
        callbackMap: {
            viewAndEdit,
            cancelExposure,
            removeExposure,
            renderValidationCell,
            saveAndClose: () => {
                updateExposure().then((valid) => {
                    if (valid) {
                        syncWizardData(null);
                    }
                });
            },
            saveAndNext: () => {
                updateExposure().then((valid) => {
                    if (valid) {
                        onNextExposure();
                    }
                });
            },
        },
        componentMap: {
            exposurecomponent: ExposureComponent,
        },
    };
    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={onPageNext}
            disableNext={!allDataValid()}
            showNext={!currentRow}
            showPrevious={!currentRow}
            showCancel={!currentRow}
            pageLevelValidationIssues={validationIssues}
            showEntityNameInPageLevelIssues
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onValueChange={writeValue}
                showErrors={showErrors}
                onValidationChange={onValidate}
                {...resolvers}
            />
        </WizardPage>
    );
}

export default GLExposuresPage;
