import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import React, { useCallback, useState, useEffect } from 'react';
import { CPLocationService } from 'wni-capability-quoteandbind-cp';
import { useWniModal } from 'wni-components-platform-react';
import { PortalConstants } from 'wni-portals-config-js';
import metadata from './CPLocationComponent.metadata.json5';
import CPLocationDetails from './LocationDetails/CPLocationDetails';
import LocationClauses from './LocationClauses';

function CPLocationComponent(props) {
    const modalApi = useWniModal();
    const {
        currentRow = {},
        locationVM,
        submissionVM,
        updateWizardData,
        writeValue,
        onValidate,
        showErrors,
        readOnly,
        externalData: { jobID, sessionUUID, authHeader },
    } = props;

    const { publicID: locationPublicID } = locationVM.value;

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');
    const newComponentProps = _.omit(props, 'id');
    const [locationClauses, setLocationClauses] = useState();

    const initData = useCallback(async () => {
        if(!locationPublicID) {
            return
        };
        setLoadingMask(true)
        const newLocationClauses = await CPLocationService.getLocationClauses(
            jobID,
            sessionUUID,
            locationPublicID,
            authHeader
        );
        setLoadingMask(false)
        setLocationClauses(newLocationClauses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authHeader, jobID, locationPublicID, sessionUUID]);

    useEffect(() => {
        initData();
    }, [initData]);

    const setWizardDataToDraft = useCallback((newSubmissionVM) => {
        // Refactoring Notice: Consider to extract this into a common method
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        return newSubmissionVM
    }, []);

    const updateWizardDataWhileSetPeriodStatus = (newSubmissionVM) => {
        const updatedSubmissionVM = setWizardDataToDraft(newSubmissionVM)
        updateWizardData(updatedSubmissionVM)
    }

    const overrideProps = {
        '@field': {
            ...newComponentProps,
        },
        locationdetails: {
            setLocationClauses,
        },
        locationClauses: {
            updateWizardDataWhileSetPeriodStatus: updateWizardDataWhileSetPeriodStatus,
            onValidate: onValidate,
            locationClauses,
            setLocationClauses,
        }
    };

    const resolvers = {
        callbackMap: {},
        componentMap: {
            locationdetails: CPLocationDetails,
            LocationClauses: LocationClauses
        },
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentRow}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            showErrors={showErrors}
            onValidationChange={onValidate}
            {...resolvers}
        />
    );
}

export default CPLocationComponent;
