import { defineMessages } from 'react-intl';

export default defineMessages({
    SearchAddressBook: {
        id: 'wni.quoteandbind.cp-wizard.Building.MortgageeDetails.SearchFromAddressBookPopup.Search Address Book',
        defaultMessage: 'Search Address Book',
    },
    Type: {
        id: 'wni.quoteandbind.cp-wizard.Building.MortgageeDetails.SearchFromAddressBookPopup.Type',
        defaultMessage: 'Type',
    },
    Bank: {
        id: 'wni.quoteandbind.cp-wizard.Building.MortgageeDetails.SearchFromAddressBookPopup.Bank',
        defaultMessage: 'Bank',
    },
    BankNameIsAnExactMatch: {
        id: 'wni.quoteandbind.cp-wizard.Building.MortgageeDetails.SearchFromAddressBookPopup.Bank name is an exact match',
        defaultMessage: 'Bank name is an exact match',
    },
    TaxID: {
        id: 'wni.quoteandbind.cp-wizard.Building.MortgageeDetails.SearchFromAddressBookPopup.Tax ID (EIN)',
        defaultMessage: 'Tax ID (EIN)',
    },
    WorkPhone: {
        id: 'wni.quoteandbind.cp-wizard.Building.MortgageeDetails.SearchFromAddressBookPopup.Work Phone',
        defaultMessage: 'Work Phone',
    },
    Search: {
        id: 'wni.quoteandbind.cp-wizard.Building.MortgageeDetails.SearchFromAddressBookPopup.Search',
        defaultMessage: 'Search',
    },
    bankNameValidationMessage: {
        id: 'wni.quoteandbind.cp-wizard.Building.MortgageeDetails.SearchFromAddressBookPopup.At least 5 characters or search by FEIN or phone',
        defaultMessage: 'Please specify at least the first five characters of the company name. Alternatively, you may search by FEIN or phone number'
    },
    taxIDFormatNotCorrectMessage: {
        id: 'wni.quoteandbind.cp-wizard.Building.MortgageeDetails.SearchFromAddressBookPopup.taxIDFormatNotCorrectMessage',
        defaultMessage: 'Must either be a nine-digit social security number with hyphens after the third and fifth digits, or a nine-digit employer identification number with a hyphen after the second digit'
    }
});