import React from 'react';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './IMBuildingDetailModal.metadata.json5';

function IMBuildingDetailModal(props) {
    const {
        size,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        title,
        data,
        diffType,
        useDependenciesData
    } = props;

    const handleSave = () => {

    }

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            showRequired: true
        },

    };
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        },
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} />
            <ModalBody id="imBuildingDetailModal">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    model={{}}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">
                    {cancelBtnLabel}
                </Button>
                <Button onClick={handleSave}>
                    {actionBtnLabel}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

IMBuildingDetailModal.propTypes = {
   
};
IMBuildingDetailModal.defaultProps = {
    size: 'md'
};
export default IMBuildingDetailModal;
