import { defineMessages } from 'react-intl';

export default defineMessages({
    AdditionalExclusions: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.additional exclusions",
        "defaultMessage": "Additional Exclusions"
    },
    AddExclusions: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.Add Exclusions",
        "defaultMessage": "Add Exclusions"
    },
    AddedExclusions: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.Added Exclusions",
        "defaultMessage": "Added Exclusions"
    },
});