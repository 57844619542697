import { defineMessages } from 'react-intl';

export default defineMessages({
    locationDetailModalTitle: {
        id: 'wni.quoteandbind.im-wizard.im-locations.New/Edit Location Details',
        defaultMessage: '{operationType} Location Details',
    },
    buildingDetailModalTitle: {
        id: 'wni.quoteandbind.im-wizard.im-locations.New/Edit Building Details',
        defaultMessage: '{operationType} Building Details',
    },
});