import { ServiceManager } from '@jutro/services';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ViewModelForm,ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import React,{ useContext,useEffect } from 'react';
import { AddressInputComponent } from 'wni-capability-gateway-react';
import { CPLocationService } from 'wni-capability-quoteandbind-cp';
import metadata from './CPLocationDetails.metadata.json5';
import CPLocationDetailsFieldMap from './CPLocationDetailsFieldMap';

const RESET_LOCATIONS_PATH_MAPS = [
    'address',
    'country',
    'city',
    'county',
    'state',
];
const RESET_LOCATIONS_PATH_MAPS_ONVALUECHANGE = ['address', 'state'];

function CPLocationDetails(props) {
    const {
        locationVM,
        onValidate,
        onValueChange,
        syncWizardData,
        syncWizardDataSnapshot,
        showErrors,
        isReadOnly,
        externalData: { jobID, sessionUUID, authHeader },
        setLocationClauses,
    } = props;


    const localeService = ServiceManager.getService('locale-service');
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');


    const updateLocationService = async(requestData) => {
        if (!locationVM.address.addressLine1) {
            return;
        }
        let locationData;
        let locationClauses;
        try {
            setLoadingMask(true);
            const res = await CPLocationService.postOnChangeAction(
                jobID,
                sessionUUID,
                requestData,
                authHeader
            );
            locationData = res.location;
            locationClauses = res.locationClauses;
            setLoadingMask(false);
        } catch (e) {
            locationData = requestData;
        }
        const newLocationVM = viewModelService.clone(locationVM);
        _.set(newLocationVM, 'value', locationData);
        syncWizardDataSnapshot(newLocationVM);
        setLocationClauses(locationClauses)
    }

    const isAddressValid = () => {
        return _.get(locationVM, 'address.aspects.valid') && _.get(locationVM, 'address.aspects.subtreeValid')
    }

    const onAddressChange = async(value, path) => {
        onValueChange(value, path);
        const isValid = isAddressValid();

        if(isValid && RESET_LOCATIONS_PATH_MAPS_ONVALUECHANGE.includes(path)){
            const requestData = {
                ...locationVM.value,
                [path]: value,
                isResetLocation: true
            }
            await updateLocationService(requestData); 
        }
    };

    const onBlur = async(e, { beforeValue, model, value}) => {
        const isValid = isAddressValid();
        if(value === beforeValue || !isValid) {
            return false;
        }
        if(RESET_LOCATIONS_PATH_MAPS.includes(model)) {
            const requestData = {
                ...locationVM.value,
                isResetLocation: true
            }
            await updateLocationService(requestData);
        }
        
    };

    const onFieldMapChange = (fieldItem, path, fieldModel = {}) => {
        
        const newVm = viewModelService.clone(locationVM);
        _.set(newVm.value, path, fieldItem);
        syncWizardDataSnapshot(newVm);
        if(fieldModel.triggerFunc === 'onValueChange') {
            // _.set(currentRowVM.value, path, fieldItem); 
            const requestData = {
                ...newVm.value,
                isResetLocation: false
            }
            updateLocationService(requestData)
        }

    }

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
            readOnly: isReadOnly
        },
        addressLookup: {
            model: locationVM,
            dataPath: 'address',
            onAddressChange: onAddressChange,
            onBlur,
            hideFieldType: {
                addressType: true,
                country: true,
                pobox: true
            },
            showErrors,
            onValidate,
            readOnly: isReadOnly,
            defaultCountryRequired: false
        },
        fieldSetModel: {
            vm: locationVM,
            dataPath: 'displayables',
            onValidate,
            showErrors,
            isReadOnly,
        },
        displayables: {
            vm: locationVM,
            dataPath: 'displayables',
            onValueChange: onFieldMapChange,
            onValidate,
            // onBlur,
            // onSearch,
            // showErrors,
            // readOnly,
        },
    };

    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.pageContent, locationVM, fieldId, fieldPath, overrideProps
        );
    };

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            fieldsetmap: CPLocationDetailsFieldMap,
            addressinput: AddressInputComponent
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={locationVM}
            overrideProps={overrideProps}
            onValidationChange={onValidate}
            onValueChange={onValueChange}
            resolveValue={readValue}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

export default CPLocationDetails;
