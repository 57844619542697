import { defineMessages } from 'react-intl';

export default defineMessages({
    clLockedSubmissionInfoMessage: {
        id: 'gateway.views.quote-detail.cl.clLockedSubmissionInfoMessage',
        defaultMessage: 'This quote submission is under review by underwriting and is unavailable to view or change at this time.'
    },
    clDeclinedSubmissionInfoMessage1: {
        id: 'gateway.views.quote-detail.cl.clDeclinedSubmissionInfoMessage1',
        defaultMessage: 'The submission has been determined to not meet requirement at this point in time.'
    },
    clDeclinedSubmissionInfoMessage2: {
        id: 'gateway.views.quote-detail.cl.clDeclinedSubmissionInfoMessage2',
        defaultMessage: 'Please reach out to an Underwriter if you have any questions or concerns.'
    },
    clToCompletePointersForSubmission: {
        id: 'gateway.views.job-uwissues.cl.clToCompletePointersForSubmission',
        defaultMessage: 'Underwriting issues have been raised for this quote and action is required to proceed. You can:'
    },
    clToCompletePointersForPolicyChange: {
        id: 'gateway.views.job-uwissues.cl.clToCompletePointersForPolicyChange',
        defaultMessage: 'Underwriting issues have been raised for this policy change and action is required to proceed. You can:'
    },
    clToCompletePointersForRenewal: {
        id: 'gateway.views.job-uwissues.cl.clToCompletePointersForRenewal',
        defaultMessage: 'Underwriting issues have been raised for this renewal and action is required to proceed. You can:'
    },
    clToCompletePointersForCancellation: {
        id: 'gateway.views.job-uwissues.cl.clToCompletePointersForCancellation',
        defaultMessage: 'Underwriting issues have been raised for this cancellation and action is required to proceed. You can:'
    },
    clEditSubmission: {
        id: 'gateway.views.job-uwissues.cl.Review this quote and make any changes necessary.',
        defaultMessage: 'Review this quote and make any changes necessary.'
    },
    clEditPolicyChange: {
        id: 'gateway.views.job-uwissues.cl.Review this policy change and make any changes necessary.',
        defaultMessage: 'Review this policy change and make any changes necessary.'
    },
    clEditRenewal: {
        id: 'gateway.views.job-uwissues.cl.Review this renewal and make any changes necessary.',
        defaultMessage: 'Review this renewal and make any changes necessary.'
    },
    clEditCancellation: {
        id: 'gateway.views.job-uwissues.cl.Review this cancellation and make any changes necessary.',
        defaultMessage: 'Review this cancellation and make any changes necessary.'
    },
    clReviewJob: {
        id: 'gateway.views.job-uwissues.cl.Refer to underwriting for review of issues blocking issurance',
        defaultMessage: 'Refer to underwriting for review of issues blocking issuance.'
    },
    clWithdrawSubmission: {
        id: 'gateway.views.job.cl.submission.WithDraw Submission',
        defaultMessage: 'Withdraw Quote'
    },
    clRejectedByUWSubmission: {
        id: 'gateway.views.job.cl.Submission Denied',
        defaultMessage: 'Submission Denied'
    },
    clRejectedByUWPolicyChange: {
        id: 'gateway.views.job.cl.Policy Change Denied',
        defaultMessage: 'Policy Change Denied'
    },
    clDeniedInfoSubmission: {
        id: 'gateway.views.quote-detail.cl.clDeniedInfoSubmission',
        defaultMessage: 'This submission has been determined to not meet requirements at this point in time.'
    },
    clDeniedInfoPolicyChange: {
        id: 'gateway.views.quote-detail.cl.clDeniedInfoPolicyChange',
        defaultMessage: 'This policy change has been determined to not meet requirements at this point in time.'
    },
    clDeniedReviewInfo: {
        id: 'gateway.views.job-uwissues.cl.clDeniedReviewInfo',
        defaultMessage: 'Please reach out to an underwriter if you have any questions or concerns.'
    },
    clUnderwriterSubmission: {
        id: 'gateway.views.job-uwissues.clUnderwriterSubmission',
        defaultMessage: 'Underwriting issues have been raised for this quote'
    },
    clUnderwriterPolicyChange: {
        id: 'gateway.views.job-uwissues.clUnderwriterPolicyChange',
        defaultMessage: 'Underwriting issues have been raised for this Policy Change'
    },
    clPolicyChangeInProgress: {
        id: 'gateway.views.job-uwissues.cl.clPolicyChangeInProgress',
        defaultMessage: 'Policy Change in Progress'
    },
    clEditPolicyChangeToContinue: {
        id: 'gateway.views.job-uwissues.cl.clEditPolicyChangeToContinue',
        defaultMessage: "Click 'Edit Policy Change' to apply all updated to the policy"
    },
    clWithdrawPolicyChangeIfNeeded: {
        id: 'gateway.views.job-uwissues.cl.clWithdrawPolicyChangeIfNeeded',
        defaultMessage: "Or you can 'Withdraw Policy Change' if the change request is no longer needed"
    },
    clEditPolicyChangeBtn: {
        id: 'gateway.views.job-uwissues.cl.clEditPolicyChange',
        defaultMessage: 'Edit Policy Change'
    },
    clWithdrawPolicyChange : {
        id: 'gateway.views.job-uwissues.cl.clWithdrawPolicyChangeBtn',
        defaultMessage: 'Withdraw Policy Change'
    },
    lockedCLSubmission: {
        id: 'gateway.views.job-uwissues.cl.This Quote is Locked.',
        defaultMessage: 'This Quote is Locked.'
    },
    declinedCLSubmission: {
        id: 'gateway.views.job-uwissues.cl.Submission Declined',
        defaultMessage: 'Submission Declined'
    },
    lockedCLPolicyChange: {
        id: 'gateway.views.job-uwissues.cl.Policy Change is locked for review',
        defaultMessage: 'Policy Change is locked for review'
    },
    clLockedPolicyChangeInfoMessage: {
        id: 'gateway.views.quote-detail.cl.clLockedPolicyChangeInfoMessage',
        defaultMessage: 'Policy Change is Locked for Review by an Underwriter. No Changes have been bound'
    }
});