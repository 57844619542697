import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('imAccountsReceivableService'), method, data, additionalHeaders);
}

export default class IMAccountsReceivableService {
  
    /**
     * Invokes IMAccountsReceivableHandler.getAccountsReveivableClausesWithSync()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getAccountsReveivableClausesWithSync(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getAccountsReveivableClausesWithSync', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMAccountsReceivableHandler.getAccountsReveivableClausesWithSync()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getAccountsReveivableClauses(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getAccountsReveivableClausesWithSync', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMAccountsReceivableHandler.updateAccountsReveivableClauses()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} accountsReceivableToUpdate
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateAccountsReveivableClauses(jobNumber, sessionUUID, accountsReceivableToUpdate, authHeader = {}) {
        return processSubmission('updateAccountsReveivableClauses', [jobNumber, sessionUUID, accountsReceivableToUpdate], authHeader);
    }

    /**
     * Invokes IMAccountsReceivableHandler.updateAccountsReveivableClauses()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} scheduleDto
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateCoveragePartSchedule(jobNumber, sessionUUID, scheduleDto, authHeader = {}) {
        return processSubmission('updateCoveragePartSchedule', [jobNumber, sessionUUID, scheduleDto], authHeader);
    }

    /**
     * Invokes IMAccountsReceivableHandler.onAccountsReceivableNext()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static onAccountsReceivableNext(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('onAccountsReceivableNext', [jobNumber, sessionUUID], authHeader);
    }
}