import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './ManualLossComponent.metadata.json5';

function ManualLossComponent(props) {
    const {
        lossVM,
        isReadOnly,
        onValueChange,
        hideCategory
    } = props;


    const overrideProps = {
        '@field': {
            labelPosition: "left",
            readOnly: isReadOnly
        },
        category: {
            visible: !hideCategory
        }
    }


    const readValue = useCallback((fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.pageContent, lossVM, fieldId, fieldPath, overrideProps
        );
    }, [lossVM]);
    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={lossVM}
            overrideProps={overrideProps}
            onValueChange={onValueChange}
            resolveValue={readValue}
        />
    );
}
ManualLossComponent.propTypes = {
    isReadOnly: PropTypes.bool,
    hideCategory: PropTypes.bool
};

ManualLossComponent.defaultProps = {
    isReadOnly: false,
    hideCategory: false
};

export default ManualLossComponent;
