import React from 'react';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { AddressInputComponent } from 'wni-capability-gateway-react';
import metadata from './IMLocationDetailModal.metadata.json5';

function IMLocationDetailModal(props) {
    const {
        size,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        title,
        isReadOnly,
        showErrors,
        onValueChange,
        data,
        diffType,
        useDependenciesData
    } = props;
   console.log('props', props);

   const {
        onValidate,
        isComponentValid,
    } = useValidation('IMLocationDetailModal');

    const handleSave = () => {

    }

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            showRequired: true
        },
        addressLookup: {
            model: {
                "phone": "4252224251",
                "publicID": "pc:601",
                "rowIdPath": "pc:601",
                "isPrimary": true,
                "address_Ext": {
                  "country": "US",
                  "addressLine1": "231 2nd St",
                  "spatialPoint": {
                    "latitude": 58.301228,
                    "longitude": -134.407661
                  },
                  "state": "AK",
                  "displayName": "1: 231 2nd St, Juneau, AK",
                  "publicID": "pc:702",
                  "county": "Juneau",
                  "postalCode": "99801-1216",
                  "code_Ext": "AS01, GS06",
                  "city": "Juneau",
                  "alternateCity_Ext": "Juneau",
                  "addressVerStatus_Ext": "Valid Address",
                  "geocodeVerStatus_Ext": "Interpolated Rooftop Level",
                  "addressType": "business"
                },
                "locationNum": 1,
                "addressType": "business",
                "isNonSpecific": false,
                "isUpdate": false
            },
            dataPath: 'address_Ext',
            onAddressChange: onValueChange,
            hideFieldType: {
                // addressType: true,
                country: true,
                pobox: true
            },
            showErrors,
            onValidate,
            readOnly: isReadOnly
        },
    };
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            addressinput: AddressInputComponent,
        },
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} />
            <ModalBody id="imLocationDetailModal">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    model={{}}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">
                    {cancelBtnLabel}
                </Button>
                <Button onClick={handleSave}>
                    {actionBtnLabel}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

IMLocationDetailModal.propTypes = {
   
};
IMLocationDetailModal.defaultProps = {
    size: 'md'
};
export default IMLocationDetailModal;
