import React, {
    useEffect,
    useContext,
} from 'react'
import _ from 'lodash'
import SingleClauseContext from '../../context/IMSingleClauseContext';
import IMCoverageUtil from '../../util/IMCoverageUtil';
import CoveragesConfigContext from '../../context/IMCoveragesConfigContext';
import IMScheduleItemEditableCovTermCellInputText from './IMScheduleItemEditableCovTermCellComponents/IMScheduleItemEditableCovTermCellInputText';
import IMScheduleItemEditableCovTermCellInputYear from './IMScheduleItemEditableCovTermCellComponents/IMScheduleItemEditableCovTermCellInputYear';
import IMScheduleItemEditableCovTermCellCurrency from './IMScheduleItemEditableCovTermCellComponents/IMScheduleItemEditableCovTermCellCurrency';
import IMScheduleItemEditableCovTermCellDropdownSelect from './IMScheduleItemEditableCovTermCellComponents/IMScheduleItemEditableCovTermCellDropdownSelect';

const IMScheduleItemEditableCovTermCell = (props) => {
    const {
        term,
        scheduleItem,
        scheduleItemPath,
        scheduleItemIndex,
    } = props

    const {
        valueType,
        code_Ext: termCode,
        name
    } = term;

    const {
        scheduleItemTerms = []
    } = scheduleItem

    const {
        onValidate,
        clauseCode,
        onChangeClause,
        onChangeSubmissionAndSync,
    } = useContext(SingleClauseContext)        
    const coveragesConfig = useContext(CoveragesConfigContext);

    const {
        scheduleConfig: {
            fieldsNotNeedSyncWhileChange: {
                [clauseCode]: fieldsNotNeedSync = []
            }
        }
    } = coveragesConfig;

    const notNeedSyncImmediately = fieldsNotNeedSync.includes(termCode)

    const termID = `${clauseCode}_scheduleItem_${scheduleItemIndex}_covTerm_${termCode}`


    useEffect(() => {
        if (onValidate) {
            onValidate(!IMCoverageUtil.isTermInvalid(term), termID);
        }
        return () => {
            if (onValidate) {
                onValidate(true, termID);
            }
        }
    }, [clauseCode, onValidate, scheduleItem, scheduleItemIndex, termID, term])

    

    const setUpdatedTermToScheduleItem = async (updatedTerm) => {
        const newTerms = scheduleItemTerms.map((t) => {
            if (t.code_Ext === termCode) {
                return updatedTerm
            }
            return t
        })

        const path = `${scheduleItemPath}.scheduleItemTerms`
        if (notNeedSyncImmediately) {
            onChangeClause(newTerms, path)
            return
        }
        onChangeSubmissionAndSync(newTerms, path)
    }

    if (_.isNil(valueType)) {
        return <IMScheduleItemEditableCovTermCellDropdownSelect
            termID={termID}
            term={term}
            setUpdatedTermToScheduleItem={setUpdatedTermToScheduleItem}
        />
    }
    if (valueType === 'Money') {
        return <IMScheduleItemEditableCovTermCellCurrency
            termID={termID}
            term={term}
            setUpdatedTermToScheduleItem={setUpdatedTermToScheduleItem}
        />
    }
    if (name === 'Year' && valueType === 'shorttext') {
        return <IMScheduleItemEditableCovTermCellInputYear
            termID={termID}
            term={term}
            setUpdatedTermToScheduleItem={setUpdatedTermToScheduleItem}
        />
    }
    if (valueType === 'shorttext') {
        return <IMScheduleItemEditableCovTermCellInputText
            termID={termID}
            term={term}
            setUpdatedTermToScheduleItem={setUpdatedTermToScheduleItem}
        />
    }
    return <div>Unsupport value type</div>

}

export default IMScheduleItemEditableCovTermCell
