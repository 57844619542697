import { useTranslator } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { GLUnmannedAircraftService } from 'wni-capability-quoteandbind-gl';
import React, { useContext, useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import FieldSetMap from './UnmannedAircraftFieldMap';
import metadata from './UnmannedAircraftDetails.metadata.json5';

function UnmannedAircraftDetails(props) {
    const { 
        currentRow,
        writeValue: onValueChange,
        syncWizardData = _.noop,
        syncWizardDataSnapshot = _.noop,
        generateValidationIssues = _.noop,
        registerComponentValidation = _.noop,
        onValidate,
        showErrors,
        readOnly,
        extendProps: { jobID, sessionUUID, authHeader },
     } = props;
    const {
        location,
        locationMaps = [],
        classCode,
        classCodeMaps
    } = currentRow.value;

    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const [invalidFields, updateInvalidFields] = useState([]);
    const {
        loadingMask: { setLoadingMask }
    } = useDependencies('loadingMask');

    const classCodeOptions = classCodeMaps.map((item) => {
        return {
            code: item,
            name: item
        }
    });
    const generateOptions = () => {
        return locationMaps.map((item) => {
            return {
                code: item.publicID,
                name: item.displayName
            }
        })
    };

    const generateServerIssues = (errorsAndWarnings) => {
        const serverIssuesWithPropertyName = _.get(errorsAndWarnings, 'serverIssues_Ext.displayMssages', []);
        const serverIssues = [];
        const newInvalidFields = [];
        serverIssuesWithPropertyName.forEach(item => {
            const splitReason = _.split(item.reason, ' === ');
            const propertyName = splitReason[0]
            const newReason = splitReason[1]
            serverIssues.push({
                ...item,
                reason: splitReason[1] || item.reason
            });

            if(newReason) {
                newInvalidFields.push({
                    propertyName: propertyName,
                    validationMessage: newReason
                })
            }
        });

        _.set(errorsAndWarnings, 'serverIssues_Ext.displayMssages', serverIssues);
        generateValidationIssues(errorsAndWarnings);
        updateInvalidFields(newInvalidFields);

    }

    const updateService = async (serviceData) => {
        setLoadingMask(true);
        const res = await GLUnmannedAircraftService.postOnChangeAction(
            jobID,
            sessionUUID,
            serviceData,
            authHeader
        );
        const serverIssues = _.get(res, 'errorsAndWarnings.serverIssues_Ext.displayMssages', []);
        generateServerIssues(res.errorsAndWarnings);
        if(_.isEmpty(serverIssues)) {
            _.set(currentRow, 'value', res.unmannedAircraft);
            syncWizardDataSnapshot(currentRow);
        } 
        setLoadingMask(false);
       
    };
    const onBlur = () => {
        updateService(currentRow.value);
    };


    const onFieldMapChange = (fieldItem, path, fieldModel = {}) => {
        const initCurrentRow = viewModelService.clone(currentRow);
        _.set(initCurrentRow.value, path, fieldItem);

        if (fieldModel.triggerFunc === 'onValueChange') {
            updateService(initCurrentRow.value);
        } else {
            syncWizardData(initCurrentRow);
        }
    };

    const writeValue = (value, path) => {
        const initCurrentRow = viewModelService.clone(currentRow);
        switch(path){
            case 'location.publicID':
                // if location change, classcode need be clean
                _.set(initCurrentRow, path, value);
                syncWizardData(initCurrentRow);
                updateService(initCurrentRow.value);       
                break;
            case 'classCode':
                _.set(initCurrentRow, path, value);
                _.set(initCurrentRow, 'classDescription', null);
                syncWizardData(initCurrentRow);
                updateService(initCurrentRow.value);
                break;
            default:
                _.set(initCurrentRow, path, value);
                syncWizardData(initCurrentRow);
                break;
        }
        
    };


    const overrideProps = {
        location: {
            availableValues: generateOptions()
        },
        classCode:{
            availableValues: classCodeOptions
        },
        detailContainer: {
            visible: location?.publicID
        },
        displayables: {
            visible: classCode,
            vm: currentRow,
            invalidFields,
            dataPath: 'displayables',
            onValueChange: onFieldMapChange,
            onValidate,
            onBlur,
            showErrors,
            readOnly
        }
    };

    const resolvers = {
        callbackMap: {},
        componentMap: {
            fieldsetmap: FieldSetMap
        },
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentRow}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            showErrors={showErrors}
            onValidationChange={onValidate}
            {...resolvers}
        />
    );
}

export default UnmannedAircraftDetails;
