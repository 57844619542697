import _ from 'lodash';
import WniAccountUtil from './WniAccountsUtil';
import WniProductsMap from './WniProductsMap';

const {
    /** for product code */
    PA_PRODUCT_CODE,
    HOP_PRODUCT_CODE,
    HO_PRODUCT_CODE,
    DP_PRODUCT_CODE,
    WAL_PRODUCT_CODE,
    RT_PRODUCT_CODE,
    PU_PRODUCT_CODE,
    CA_PRODUCT_CODE,
    WCM_PRODUCT_CODE,
    GL_PRODUCT_CODE,
    CP_PRODUCT_CODE,
    IM_PRODUCT_CODE,
    CR7_PRODUCT_CODE,
    CPP_PRODUCT_CODE,
    // PRODUCT MAP LOB START Dummy
    DUMMY_PRODUCT_CODE,
    // PRODUCT MAP LOB END Dummy
    PRODUCT_LOBNAME_MAPS,

    productsMap,
    policyLinePatternForCPP,
} = WniProductsMap;

const { PERSONAL_ACCOUNT_CODE, COMMERCIAL_ACCOUNT_CODE } = WniAccountUtil;


const getAllProductsMap = () => {
    return productsMap;
};

/**
 *
 * @param {string} code product code
 * @returns {string} return product icon
 */

const getProductIcon = (code) => {
    if (!code) {
        return '';
    }
    const productItem = _.find(productsMap, (item) => item.code === code);
    return productItem.icon;
};
/**
 *
 * @param {string} code
 * @returns {string|object}
 */

const getProductName = (code) => {
    if (!code) {
        return '';
    }
    const productItem = _.find(productsMap, (item) => item.code === code);
    return _.get(productItem, 'productName');
};

/**
 *
 * @param {array} productSelected
 * @returns {array} return the sort data
 */
const getSortProductSelected = (productSelected = []) => {
    const selectedData = productsMap.filter((item) => productSelected.includes(item.code));
    const sortSlectedData = _.orderBy(selectedData, (obj) => obj.sort);
    return sortSlectedData.map((item) => item.code);
};

const getQuickLink = (productCode) => {
    const quickLinkProduct = productsMap.find(
        (item) => item.code === productCode
    );
    return quickLinkProduct.quickLink;
};

const getProductShortName = (productCode) => {
    const productItem = _.find(productsMap, (item) => item.code === productCode);
    return _.get(productItem, 'productShortName');
};

const isCLProduct = (productCode) => {
    const product = productsMap.find((item) => item.code === productCode);
    return _.get(product, 'accountType') === COMMERCIAL_ACCOUNT_CODE;
};

const isDPOrHOProduct = (productCode) => {
    if (productCode === HO_PRODUCT_CODE
        || productCode === HOP_PRODUCT_CODE
        || productCode === DP_PRODUCT_CODE) {
            return true;
        }
    return false;
}

const isProductAvailableForAccount = (productCode, accountProductAvailableMaps = []) => {
    const productItem = _.find(productsMap, (item) => item.code === productCode);
    if(!productItem) {
        return false;
    }
    const findMatch = accountProductAvailableMaps.find((item) => item.productCode === productItem.accountAvailableCode);
    return findMatch ? findMatch.isAvailable : true
};

const getPolicyLinePattern = (productCode) => {
    const findLinePatternItem = policyLinePatternForCPP.find((item) => item.productCode === productCode);
    if(!findLinePatternItem) {
        return null;
    }
    return findLinePatternItem.policyLinePattern;
};

const getPolicyLinePatternName = (linePattern) => {
    const findLinePatternItem = policyLinePatternForCPP.find((item) => item.policyLinePattern === linePattern);
    if(!findLinePatternItem) {
        return null;
    }
    return getProductName(findLinePatternItem.productCode);
};

const getPolicyLobName = (linePattern) => {
    const findLinePatternItem = policyLinePatternForCPP.find((item) => item.policyLinePattern === linePattern);
    if(!findLinePatternItem) {
        return null;
    }
    return findLinePatternItem.lobName
};

const getLobName = (productCode) => {
    return PRODUCT_LOBNAME_MAPS[productCode];
};

const getProductNameByLinePattern = (product = {}) => {
    const findLinePatternItem = policyLinePatternForCPP.find((item) => item.policyLinePattern === product.linePattern);
    if(findLinePatternItem) {
        return getProductName(findLinePatternItem.productCode)
    };

    return getProductName(product.productCode);
};

export default {
    ...WniProductsMap,

    /** for product func */
    getAllProductsMap,
    getProductIcon,
    getProductName,
    getSortProductSelected,
    getQuickLink,
    getProductShortName,
    isCLProduct,
    isDPOrHOProduct,
    isProductAvailableForAccount,

    // for CPP Lines
    policyLinePatternForCPP,
    getPolicyLinePattern,
    getPolicyLinePatternName,
    getPolicyLobName,
    getLobName,
    getProductNameByLinePattern
};