import { defineMessages } from 'react-intl';

export default defineMessages({
    priorLossInfo: {
        id : 'wni.quoteandbind.gl-wizard.gl-risk-analysis.priorLossInfo',
        defaultMessage: 'Loss History is required for all claims with a prior carrier in the past 5 years. Please manually enter the prior loss information or upload a loss run.'
    },
    removeLossTitle: {
        id: 'wni.quoteandbind.gl-wizard.gl-loss.Remove Loss?',
        defaultMessage: "Remove Manual Loss?"
    },
    removeLossDescription: {
        id: 'wni.quoteandbind.gl-wizard.gl-loss.removeLossDescription',
        defaultMessage: 'Are you sure you want to remove this manual loss entry?',
    },
    Ok: {
        id: 'wni.quoteandbind.gl-wizard.gl-loss.OK',
        defaultMessage: 'OK'
    },
    Cancel: {
        id: 'wni.quoteandbind.gl-wizard.gl-loss.Cancel',
        defaultMessage: 'Cancel'
    }
});