import React from 'react';
import { CPStateSpecificInformationReadOnlyPage } from 'wni-capability-quoteandbind-cp-react';

function CPStateSpecificInformationChangeReadOnlyPage(props) {

    return (
        <CPStateSpecificInformationReadOnlyPage {...props} />
    );

}

CPStateSpecificInformationChangeReadOnlyPage.propTypes = CPStateSpecificInformationReadOnlyPage.propTypes;
CPStateSpecificInformationChangeReadOnlyPage.defaultProps = CPStateSpecificInformationReadOnlyPage.defaultProps;

export default CPStateSpecificInformationChangeReadOnlyPage;