import { defineMessages } from 'react-intl';

export default defineMessages({
    ownersSublineErrorMessage: {
        id : 'wni.quoteandbind.gl-wizard.gl-subline-selection.ownersSublineErrorMessage',
        defaultMessage: 'Owners and Contractors must be written monoline.'
    },
    railroadSublineErrorMessage: {
        id : 'wni.quoteandbind.gl-wizard.gl-subline-selection.railroadSublineErrorMessage',
        defaultMessage: 'Railroad must be written monoline.'
    }
});