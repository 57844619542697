import { defineMessages } from 'react-intl';

export default defineMessages({
    policyHolder: {
        id: 'quoteandbind.wizard.step.AccountHolder',
        defaultMessage: 'Account Holder'
    },
    newAccountCreated: {
        id: 'quoteandbind.wizard.step.A new account has been created',
        defaultMessage: 'A new account has been created'
    },
    name: {
        id: 'quoteandbind.wizard.step.policyholder.name',
        defaultMessage: 'Name'
    },
    productEmptyMessage: {
        id: 'gateway.views.account.cl.At least one product must be selected',
        defaultMessage: 'At least one product must be selected'
    },
    l_2: {
        id: 'gateway.views.account.cl.hazard code.level 2',
        defaultMessage: 'This application contains risk identified as moderate hazard rating and must be referred to an underwriter for review prior to providing a quote. If the application meets underwriting eligibility and guidelines, a quote will be provided. Your underwriter will contact you if any additional information is needed to offer a quote'
    },
    l_3: {
        id: 'gateway.views.account.cl.hazard code.level 3',
        defaultMessage: 'This application contains risk identified as moderate hazard rating and must be referred to an underwriter for review prior to providing a quote. If the application meets underwriting eligibility and guidelines, a quote will be provided. Your underwriter will contact you if any additional information is needed to offer a quote'
    },
    l_4: {
        id: 'gateway.views.account.cl.hazard code.level 4',
        defaultMessage: 'Due to the High risk characteristics of this policy is cannot be created in agent portal'
    },
    l_5: {
        id: 'gateway.views.account.cl.hazard code.level 5',
        defaultMessage: 'Due to the High risk characteristics of this policy is cannot be created in agent portal'
    },
    // l_4: {
    //     id: 'gateway.views.account.cl.This Application contains high risk characteristics that cannot be quoted in the portal for: [List out LoBs with Harazrd ratings of L4 or L5]. We ask that you reach out to an underwriter to continue the application process for these products.',
    //     defaultMessage: 'This Application contains high risk characteristics that cannot be quoted in the portal for: {Lob}. We ask that you reach out to an underwriter to continue the application process for these products.'
    // },
    // l_5: {
    //     id: 'gateway.views.account.cl.This Application contains high risk characteristics that cannot be quoted in the portal for: [List out LoBs with Harazrd ratings of L4 or L5]. We ask that you reach out to an underwriter to continue the application process for these products.',
    //     defaultMessage: 'This Application contains high risk characteristics that cannot be quoted in the portal for: {Lob}. We ask that you reach out to an underwriter to continue the application process for these products.'
    // }
    imIncludedPackageInfo: {
        id: 'quoteandbind.wizard.step.Click here to review coverage offerings for the Commercial Package Inland Marine and the mono-line Inland Marine products',
        defaultMessage:'to review coverage offerings for the Commercial Package Inland Marine and the mono-line Inland Marine products'
    },
});
